<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 float-right d-flex align-items-center"
          :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item d-flex align-items-center"
            v-if="!storeAuth.isLogin"
          >
            <router-link
              :to="{ name: 'Sign In' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <span class="d-sm-inline d-none">Sign In </span>
            </router-link>
          </li>

          <li
            class="nav-item d-flex align-items-center mx-2"
            v-if="storeAuth.isLogin"
          >
            <span class="d-sm-inline d-none"
              >Selamat Datang, {{ storeAuth.name }}
            </span>

            <!-- <span class="d-sm-inline d-none" @click="logout"> Logout </span> -->
          </li>
          <li
            class="nav-item d-flex align-items-center"
            v-if="storeAuth.isLogin"
          >
            <span
              class="d-sm-inline d-none text-danger cursor-pointer"
              @click="logout"
            >
              Logout
            </span>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      storeAuth,
      url: `${this.apiUrl}/logout`,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout: function () {
      if (JSON.parse(localStorage.getItem("user")).accessToken) {
        axios
          .get(this.url, {
            headers: { Authorization: storeAuth.accessToken },
          })
          .then(
            (res) => {
              if (res.data.success) {
                sweatalert.showAlert("success", res.data.message);

                this.logoutUser();
              }
            },
            (err) => {
              if (err.response.data.message) {
                sweatalert.showAlert("error", err.response.data.message);

                if (err.response.status == 401) {
                  this.logoutUser();
                }
              } else {
                sweatalert.showAlert("error", "Maaf telah terjadi kesalahan");
              }
            }
          );
      }
    },
    logoutUser() {
      localStorage.removeItem("user");
      storeAuth.isLogin = false;
      storeAuth.user.name = null;

      this.$router.push({ name: "Sign In" });
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loggedIn() {
      if (JSON.parse(localStorage.getItem("user"))) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      storeAuth.isLogin = true;
      storeAuth.name = JSON.parse(localStorage.getItem("user")).name;
      storeAuth.role = JSON.parse(localStorage.getItem("user")).role;
      storeAuth.accessToken =
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken;
    }
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
