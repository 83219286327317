<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col-6 align-items-center">
                <h6>Daftar Harga Khusus</h6>
              </div>
              <div class="col-12 align-items-center">
                <div class="form-group">
                  <label class="form-label mb-0">Lokasi</label>
                  <select
                    class="form-select"
                    style="max-width: 500px"
                    aria-label="Lokasi"
                    v-model="data.location"
                    @change="getDataProduct()"
                  >
                    <option value="" selected>Pilih Lokasi</option>
                    <option
                      :value="location.id"
                      v-for="location in data.locations"
                      :key="location.id"
                    >
                      {{ location.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <table-prices
            v-bind:prices="data.prices"
            v-bind:products="data.products"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TablePrices from "./components/TablePrices";

export default {
  name: "product-list",
  components: {
    TablePrices,
  },
  data() {
    return {
      data: {
        prices: [],
        location: {
          id: "",
        },
        locations: [],
        products: [],
      },
      url: {
        urlGetLocations: `${this.apiUrl}/location`,
        urlGetPrices: `${this.apiUrl}/product/price`,
        urlGetProducts: `${this.apiUrl}/product`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getProducts();
    this.getDataLocation();
  },
  methods: {
    getProducts() {
      axios
        .get(this.url.urlGetProducts, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          console.log(this.url.urlGetProducts, "getProducts");
          this.data.products = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataLocation() {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;

          this.data.location = this.data.locations[0].id;

          this.getDataProduct();
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProduct() {
      axios
        .get(this.url.urlGetPrices + `?location=${this.data.location}`, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          console.log(this.data.prices);
          this.data.prices = res.data.data;
          console.log(this.data.prices);
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
