<style scoped>
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  margin: 0;
}
</style>

<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item" v-if="user.role < 3">
        <sidenav-collapse
          navText="Kategori"
          :to="{ name: 'Kategori' }"
          @click="checkUrl"
        >
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role < 3">
        <sidenav-collapse
          navText="Produk"
          :to="{ name: 'Produk' }"
          @click="checkUrl"
        >
          <template #icon>
            <box3d />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role < 3">
        <sidenav-collapse
          navText="Harga Khusus"
          :to="{ name: 'Harga Khusus' }"
          @click="checkUrl"
        >
          <template #icon>
            <box3d />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText="Daftar Produk"
          :to="{ name: 'Daftar Produk' }"
          @click="checkUrl"
        >
          <template #icon>
            <box3d />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse-tree
          navText="Pembelian"
          :to="{ name: 'Pembelian' }"
          :id="'purchases_collapse'"
          :url="url"
        >
          <template #icon>
            <document />
          </template>
          <template #tree>
            <li class="nav-item">
              <sidenav-tree
                navText="Daftar Pembelian"
                :to="{ name: 'Pembelian' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item" v-if="user.role < 3">
              <sidenav-tree
                navText="Tambah Pembelian"
                :to="{ name: 'Tambah Pembelian' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Retur Pembelian"
                :to="{ name: 'Retur Pembelian' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Tambah Retur Pembelian"
                :to="{ name: 'Tambah Retur Pembelian' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
          </template>
        </sidenav-collapse-tree>
      </li>
      <li class="nav-item">
        <sidenav-collapse-tree
          navText="Penjualan"
          :to="{ name: 'Penjualan' }"
          :id="'sales_collapse'"
          :url="url"
        >
          <template #icon>
            <document />
          </template>
          <template #tree>
            <li class="nav-item">
              <sidenav-tree
                navText="Daftar Penjualan"
                :to="{ name: 'Penjualan' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Tambah Penjualan"
                :to="{ name: 'Tambah Penjualan' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Retur Penjualan"
                :to="{ name: 'Retur Penjualan' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Tambah Retur Penjualan"
                :to="{ name: 'Tambah Retur Penjualan' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
          </template>
        </sidenav-collapse-tree>
      </li>
      <li class="nav-item" v-if="user.role < 3">
        <sidenav-collapse
          navText="Lokasi Cabang"
          :to="{ name: 'Lokasi' }"
          @click="checkUrl"
        >
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role < 4">
        <sidenav-collapse
          navText="Pengguna"
          :to="{ name: 'Pengguna' }"
          @click="checkUrl"
        >
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse-tree
          navText="Laporan"
          :to="{ name: 'Laporan Penjualan' }"
          :id="'reports_collapse'"
          :url="url"
        >
          <template #icon>
            <document />
          </template>
          <template #tree>
            <li class="nav-item">
              <sidenav-tree
                navText="Laporan Penjualan"
                :to="{ name: 'Laporan Penjualan' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
            <li class="nav-item">
              <sidenav-tree
                navText="Laporan Produk"
                :to="{ name: 'Laporan Produk' }"
                @click="checkUrl"
              >
              </sidenav-tree>
            </li>
          </template>
        </sidenav-collapse-tree>
      </li>
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer"></div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseTree from "./SidenavCollapseTree.vue";
import SidenavTree from "./SidenavTree.vue";
import Office from "../../components/Icon/Office.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Document from "../../components/Icon/Document.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      user: { role: null },
      url: window.location.href.split("/")[3],
    };
  },
  components: {
    SidenavCollapse,
    SidenavCollapseTree,
    SidenavTree,
    Office,
    Box3d,
    Document,
  },
  mounted() {
    this.user = this.$root.$refs["navbar"].storeAuth;
    this.url = window.location.href.split("/")[3];
  },
  methods: {
    checkUrl(event) {
      this.url = event.currentTarget.getAttribute("href").split("/")[1];
    },
  },
};
</script>
