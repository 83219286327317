<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-body pb-2">
            <form
              ref="form"
              action="#"
              method="post"
              role="form"
              v-on:submit.prevent="submit()"
            >
              <div class="row border-bottom">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="form-label">Nomor Invoice</label>
                    <input
                      type="text"
                      class="form-control"
                      list="invoices"
                      placeholder="Type to search..."
                      autocomplete="off"
                      @input="
                        searchPurchase();
                        selectPurchase();
                      "
                      v-model="searchValue"
                    />
                    <datalist id="invoices">
                      <option
                        :value="invoice.invoice + ' | ' + invoice.location"
                        v-for="invoice in purchasesOptions"
                        :key="invoice.id"
                      ></option>
                    </datalist>
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="invoice">
                  <div class="form-group">
                    <label class="form-label">Tanggal Transaksi</label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Nama Produk"
                      v-model="invoice.date"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="invoice">
                  <div class="form-group">
                    <label class="form-label">Kasir</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Admin"
                      v-model="invoice.cashier"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3" v-if="invoice">
                  <label class="form-label mb-0">Daftar Produk</label>
                  <div class="table-responsive p-0">
                    <table
                      class="table align-items-center justify-content-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Kode Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Nama Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Pembelian
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Harga
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Total Retur
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Jumlah Retur
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="detail in invoice.details" :key="detail.id">
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ detail.item.code }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ detail.item.name }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ parseInt(detail.quantity) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(detail.price) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ parseInt(detail.quantity_return) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <input
                                  type="number"
                                  style="max-width: 100px"
                                  min="0"
                                  class="form-control"
                                  placeholder="Jumlah"
                                  @input="calculateTotal()"
                                  v-model="detail.qty_return"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(detail.total) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="background-color: #c0f3ff">
                          <td
                            colspan="5"
                            class="text-uppercase font-weight-bolder text-end"
                          >
                            Total
                          </td>
                          <td
                            class="text-uppercase font-weight-bolder text-center"
                          >
                            {{ invoice.qty_return }}
                          </td>
                          <td class="text-uppercase font-weight-bolder">
                            {{ formatNumber(invoice.total) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row my-3" v-if="invoice">
                <div class="col-12 text-end">
                  <soft-button
                    color="success"
                    size="sm"
                    variant="outline"
                    :disable="disable"
                  >
                    <span
                      v-if="disable"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ disable ? " Loading..." : "Buat Transaksi" }}
                  </soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import SoftButton from "@/components/SoftButton.vue";
// import Select2 from "vue3-select2-component";

export default {
  name: "add-purchase-return",
  components: {
    SoftButton,
    // Select2,
  },
  data() {
    return {
      invoice: null,
      purchases: [],
      purchasesOptions: [],
      searchValue: "",
      disable: false,
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
      url: {
        urlSubmit: `${this.apiUrl}/purchase/return`,
        urlGetPurchases: `${this.apiUrl}/purchase`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataPurchase();
  },
  methods: {
    submit: function () {
      if (
        confirm(
          "Anda yakin ingin membuat transaksi ini? Ini tidak dapat dibatalkan."
        )
      ) {
        this.disable = true;
        axios
          .post(this.url.urlSubmit, this.invoice, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            sweatalert.showAlert("success", response.data.message);
            this.$router.push({ name: "Retur Pembelian" });
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            Object.keys(this.errors).forEach((key) => {
              sweatalert.showAlert("error", this.errors[key]);
            });
            this.disable = false;
          });
      }
    },
    getDataPurchase: function () {
      axios
        .get(this.url.urlGetPurchases, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.purchases = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    searchPurchase: function () {
      this.purchasesOptions = [];
      const reg = new RegExp(this.searchValue);
      this.purchases.forEach((element) => {
        if (
          element.invoice.toLowerCase().match(reg) ||
          element.location.toLowerCase().match(reg)
        ) {
          this.purchasesOptions.push(element);
        }
      });
    },
    selectPurchase: function () {
      let invoice = this.purchases.find((item) => {
        return item.invoice + " | " + item.location === this.searchValue;
      });
      if (invoice) {
        this.invoice = invoice;
        this.invoice.details.forEach((element) => {
          element.qty_return = 0;
        });
      }
    },
    calculateTotal: function () {
      let total = 0;
      let qtyReturn = 0;
      this.invoice.details.forEach((element) => {
        if (element.qty_return > element.quantity - element.quantity_return) {
          element.qty_return = 0;
          sweatalert.showAlert("error", "Stok produk melebihi yang dibeli");
        }

        element.total = element.qty_return * element.price;
        total += element.total;
        qtyReturn += element.qty_return;
      });
      this.invoice.total = total;
      this.invoice.qty_return = qtyReturn;
    },
    formatNumber: function (number) {
      if (number)
        return (
          "Rp" +
          parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        );

      return "Rp0,00";
    },
  },
};
</script>
