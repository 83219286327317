<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="purchaseReturns"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span v-if="props.column.field == 'action'"
          ><a
            href="javascript:;"
            class="text-danger font-weight-bold text-xs"
            data-toggle="tooltip"
            data-original-title="Edit user"
            @click="getPurchase(props.row.id)"
            >Detail</a
          >
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["purchaseReturns"],
  name: "good-table-purchase-returns",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: "Invoice",
          field: "invoice",
        },
        {
          label: "Tanggal",
          field: "date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Lokasi",
          field: "location",
        },
        {
          label: "Total",
          field: "total_f",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Opsi",
          field: "action",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  methods: {
    getPurchase(id) {
      this.$parent.getPurchase(id);
    },
  },
};
</script>
