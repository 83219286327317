<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-sale-returns v-bind:saleReturns="data.saleReturns" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableSaleReturns from "./components/TableSaleReturns";

export default {
  name: "sale-returns",
  components: {
    TableSaleReturns,
  },
  data() {
    return {
      data: {
        saleReturns: [],
      },
      url: {
        urlGetSaleReturns: `${this.apiUrl}/sale/return`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataSaleReturns();
  },
  methods: {
    getDataSaleReturns: function () {
      axios
        .get(this.url.urlGetSaleReturns, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.saleReturns = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
