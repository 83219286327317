<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="products"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span v-if="props.column.field == 'action'">
          <soft-button
            color="info"
            size="sm"
            variant="outline"
            class="text-lowercase px-2 me-2"
            @click="openModalBarcode(props.row.id)"
            >barcode</soft-button
          >
          <soft-button
            color="primary"
            size="sm"
            variant="outline"
            class="text-lowercase px-2"
            @click="openModalDetail(props.row.id)"
            >transaksi</soft-button
          >
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["products"],
  name: "good-table-product-list",
  components: {
    SoftButton,
    VueGoodTable,
  },
  data() {
    return {
      dataProducts: this.products,
      columns: [
        {
          label: "Nama Produk",
          field: "name",
        },
        {
          label: "Kategori",
          field: "product_category",
        },
        {
          label: "Sub Kategori",
          field: "product_sub_category",
        },
        {
          label: "Merek",
          field: "product_brand",
        },
        {
          label: "Stok",
          field: "stock",
        },
        {
          label: "Harga Jual",
          field: "price_f",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Option",
          field: "action",
          tdClass: "w-px-1",
          thClass: "w-px-1",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  mounted() {
    if (JSON.parse(localStorage.getItem("user")).role == 1)
      this.columns.splice(3, 0, {
        label: "Komisi",
        field: "product_commission",
      });
  },
  updated() {
    if (this.dataProducts.length == 0) {
      this.dataProducts = this.products;
    }
  },
  methods: {
    openModalCreate() {
      this.$parent.openModalCreate();
    },
    openModalEdit(id) {
      this.$parent.openModalEdit(id);
    },
    deleteProduct: function (id) {
      this.$parent.deleteProduct(id);
    },
    openModalBarcode(id) {
      this.$parent.openModalBarcode(id);
    },
    openModalDetail(id) {
      this.$parent.openModalDetail(id);
    },
  },
};
</script>
