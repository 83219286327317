<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="products"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span
          v-if="
            props.column.field == 'barcode_url' ||
            props.column.field == 'action'
          "
        >
          <span v-if="props.column.field == 'barcode_url'">
            <soft-button
              color="primary"
              size="sm"
              variant="outline"
              class="text-lowercase px-2"
              @click="openModalBarcode(props.row.id)"
              >show</soft-button
            >
          </span>
          <span v-if="props.column.field == 'action'">
            <a
              href="javascript:;"
              class="text-success font-weight-bold text-xs mx-2"
              data-toggle="tooltip"
              data-original-title="Edit user"
              @click="openModalEdit(props.row.id)"
              >Edit</a
            >
            <a
              href="javascript:;"
              class="text-danger font-weight-bold text-xs"
              data-toggle="tooltip"
              data-original-title="Edit user"
              @click="deleteProduct(props.row.id)"
              >Hapus</a
            >
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["products"],
  name: "my-table",
  components: {
    SoftButton,
    VueGoodTable,
  },
  data() {
    return {
      dataProducts: this.products,
      columns: [
        {
          label: "Nama Produk",
          field: "name",
        },
        {
          label: "Kategori",
          field: "product_category",
        },
        {
          label: "Sub Kategori",
          field: "product_sub_category",
        },
        {
          label: "Merek",
          field: "product_brand",
        },
        {
          label: "Komisi",
          field: "product_commission",
          tdClass: "w-px-1",
          thClass: "w-px-1",
        },
        {
          label: "Harga Jual",
          field: "price_f",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Barcode",
          field: "barcode_url",
          tdClass: "w-px-1",
          thClass: "w-px-1",
        },
        {
          label: "",
          field: "action",
          tdClass: "w-px-1",
          thClass: "w-px-1",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataProducts.length == 0) {
      this.dataProducts = this.products;
    }
  },
  methods: {
    openModalCreate() {
      this.$parent.openModalCreate();
    },
    openModalEdit(id) {
      this.$parent.openModalEdit(id);
    },
    deleteProduct: function (id) {
      this.$parent.deleteProduct(id);
    },
    openModalBarcode(id) {
      this.$parent.openModalBarcode(id);
    },
  },
};
</script>
