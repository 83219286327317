<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-locations v-bind:locations="data.locations" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableLocations from "./components/TableLocations.vue";

export default {
  name: "Locations",
  components: {
    TableLocations,
  },
  data() {
    return {
      data: {
        locations: [],
      },
      url: {
        urlGetLocations: `${this.apiUrl}/location`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataLocation();
  },
  methods: {
    getDataLocation: function () {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
