<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-purchase-returns v-bind:purchaseReturns="data.purchaseReturns" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TablePurchaseReturns from "./components/TablePurchaseReturns";

export default {
  name: "purchase-returns",
  components: {
    TablePurchaseReturns,
  },
  data() {
    return {
      data: {
        purchaseReturns: [],
      },
      url: {
        urlGetPurchaseReturns: `${this.apiUrl}/purchase/return`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataPurchaseReturns();
  },
  methods: {
    getDataPurchaseReturns: function () {
      console.log("hoam");
      axios
        .get(this.url.urlGetPurchaseReturns, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.purchaseReturns = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
