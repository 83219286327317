export default ({
    next,
    router
}) => {
    if (localStorage.getItem(`user`)) {
        if (JSON.parse(localStorage.getItem("user")).accessToken) {
            return router.push({
                name: `Dashboard`
            });
        }
    }

    return next();
}