<style scoped>
.dropdown-menu.show {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-23.6px, 110px, 0px);
  background-color: #d8d8d8;
}
</style>
<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-header">
            <form
              :action="url.urlGetSales + '/generate'"
              @submit="checkForm()"
              method="POST"
              target="_blank"
              enctype="multipart/form-data"
            >
              <input type="text" hidden name="data" :value="data.dataSubmit" />
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <h6 class="mb-0">Laporan Penjualan</h6>
                </div>
                <div class="col-sm-4 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Tipe Laporan</label>
                    <select
                      class="form-select"
                      aria-label="Tipe"
                      v-model="data.type"
                      @change="getDataSales()"
                    >
                      <option
                        :value="type.id"
                        v-for="type in data.types"
                        :key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Lokasi</label>
                    <select
                      class="form-select"
                      aria-label="Lokasi"
                      v-model="data.location"
                      @change="getDataSales()"
                    >
                      <option value="" selected>Pilih Lokasi</option>
                      <option
                        :value="location.id"
                        v-for="location in data.locations"
                        :key="location.id"
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Tanggal</label>
                    <Datepicker
                      v-model="data.date"
                      range
                      multiCalendars
                      :presetRanges="presetRanges"
                      @update:modelValue="getDataSales()"
                    ></Datepicker>
                  </div>
                </div>
                <div class="col-sm-4 align-items-center" v-if="data.type == 3">
                  <div class="form-group">
                    <label class="form-label mb-0"
                      >Kelompokkan Berdasarkan</label
                    >
                    <select
                      class="form-select"
                      aria-label="Tipe"
                      v-model="data.groupBy"
                      @change="getDataSales()"
                    >
                      <option
                        :value="type.id"
                        v-for="type in [
                          { id: 1, name: 'Kategori Produk' },
                          { id: 2, name: 'Komisi' },
                        ]"
                        :key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-12 d-flex align-items-center justify-content-end"
                >
                  <a
                    href="#"
                    class="p-0 nav-link"
                    :class="[showMenu ? 'show' : '']"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="showMenu = !showMenu"
                  >
                    <soft-button color="info" size="sm" variant="outline"
                      >Cetak</soft-button
                    >
                  </a>
                  <ul
                    class="px-2 py-3 dropdown-menu dropdown-menu-end"
                    :class="showMenu ? 'show' : ''"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li class="mb-2">
                      <button
                        type="submit"
                        class="dropdown-item border-radius-md"
                        @focus="printType = 'pdf'"
                      >
                        <div class="py-1 d-flex">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="mb-1 text-sm font-weight-normal">
                              <span class="font-weight-bold">PDF</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button
                        type="submit"
                        class="dropdown-item border-radius-md"
                        @focus="printType = 'excel'"
                      >
                        <div class="py-1 d-flex">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="mb-1 text-sm font-weight-normal">
                              <span class="font-weight-bold">Excel</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body p-0 w-100">
            <div class="row px-4">
              <div class="col-xxl-3 col-lg-4 col-sm-6 col-12 mb-xl-0">
                <mini-statistics-card
                  title="Total Penjualan"
                  :value="data.total.total_sales"
                  :icon="{
                    component: 'ni ni-money-coins',
                    background: iconBackground,
                  }"
                  direction-reverse
                />
              </div>
              <div class="col-xxl-3 col-lg-4 col-sm-6 col-12 mb-xl-0 mb-4">
                <mini-statistics-card
                  :title="
                    data.type == 1
                      ? 'Total Invoice'
                      : data.type == 2
                      ? 'Total Jenis Produk'
                      : 'Total Produk Terjual'
                  "
                  :value="data.total.total_invoice"
                  :icon="{
                    component: ' ni ni-world',
                    background: iconBackground,
                  }"
                  direction-reverse
                />
              </div>
            </div>
            <div class="table-responsive p-0">
              <table-sale v-bind:sales="this.data.sales" v-if="data.type == 1">
              </table-sale>
              <table-sale-detail
                v-bind:sales="this.data.sales"
                v-if="data.type == 2"
              >
              </table-sale-detail>
              <table-total-product
                v-bind:sales="this.data.sales"
                v-if="data.type == 3"
              >
              </table-total-product>
            </div>
          </div>
        </div>
        <!-- <table-sales v-bind:sales="data.sales" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableSale from "./components/GoodTableSales";
import TableSaleDetail from "./components/GoodTableSaleDetails";
import TableTotalProduct from "./components/GoodTableTotalProducts";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { ref } from "vue";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
// import TableSales from "./components/TableSales";

export default {
  name: "report-sales",
  setup() {
    const date = ref();

    const presetRanges = ref([
      { label: "Today", range: [new Date(), new Date()] },
      {
        label: "This month",
        range: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
      {
        label: "Last month",
        range: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ],
      },
      {
        label: "This year",
        range: [startOfYear(new Date()), endOfYear(new Date())],
      },
    ]);

    return {
      date,
      presetRanges,
    };
  },
  components: {
    SoftButton,
    TableSale,
    TableSaleDetail,
    TableTotalProduct,
    Datepicker,
    MiniStatisticsCard,
    // TableSales,
  },
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      data: {
        type: 1,
        types: [
          { id: 1, name: "Rekap Penjualan" },
          { id: 2, name: "Detail Penjualan" },
          { id: 3, name: "Total Penjualan Produk" },
        ],
        sales: [],
        location: null,
        locations: [],
        date: null,
        dataSubmit: null,
        dateSubmit: [null, null],
        groupBy: 1,
        total: {
          total_sales: 0,
          total_invoice: 0,
        },
      },
      showMenu: false,
      printType: null,
      url: {
        urlGetLocations: `${this.apiUrl}/location`,
        urlGetSales: `${this.apiUrl}/reports/sales`,
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataLocation();
    // this.getDataSales();
  },
  methods: {
    getDataLocation: function () {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;

          this.data.location = this.data.locations[0].id;

          this.getDataSales();
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataSales: function () {
      const data = {
        type: this.data.type,
        location: this.data.location,
        group_by: this.data.groupBy,
        date: this.data.date
          ? [
              moment(this.data.date[0]).format("YYYY-MM-DD"),
              this.data.date[1]
                ? moment(this.data.date[1]).format("YYYY-MM-DD")
                : null,
            ]
          : null,
      };

      axios
        .get(this.url.urlGetSales + `?data=${JSON.stringify(data)}`, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.sales = res.data.data;
          this.data.total.total_sales = res.data.total_sales;
          this.data.total.total_invoice = res.data.total_invoice;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    checkForm() {
      const data = {
        print_type: this.printType,
        type: this.data.type,
        location: this.data.location,
        group_by: this.data.groupBy,
        date: this.data.date
          ? [
              moment(this.data.date[0]).format("YYYY-MM-DD"),
              this.data.date[1]
                ? moment(this.data.date[1]).format("YYYY-MM-DD")
                : null,
            ]
          : null,
      };

      this.data.dataSubmit = JSON.stringify(data);
    },
  },
};
</script>
