<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Kategori Produk</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="openModalCreate()"
            >Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <good-table-categories v-bind:categories="this.dataProductCategories">
        </good-table-categories>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Nama Kategori</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama Kategori"
                    v-model="category.name"
                  />
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableCategories from "./GoodTableCategories.vue";

export default {
  props: ["productCategories"],
  name: "table-categories",
  components: {
    SoftButton,
    Modal,
    GoodTableCategories,
  },
  data() {
    return {
      dataProductCategories: this.productCategories,
      listCategories: null,
      urlCategory: `${this.apiUrl}/product/category`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahCategory",
      },
      category: {
        id: "",
        name: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataProductCategories.length == 0) {
      this.dataProductCategories = this.productCategories;
    }
  },
  methods: {
    submit(method) {
      if (method == "POST") {
        this.tambahCategory();
      } else if (method == "PUT") {
        this.editCategory();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahCategory";
      this.modal.title = "Tambah Kategori";
      this.category.name = "";
    },
    openModalEdit(categoryId) {
      this.errors = "";

      const selectedCategory = this.productCategories.find(
        (category) => category.id === categoryId
      );

      this.modal.status = true;
      this.modal.method = "PUT";
      this.modal.methodFuction = "updateCategory";
      this.modal.title = "Edit Kategori";
      this.modal.button = "Simpan";
      this.category.id = selectedCategory.id;
      this.category.name = selectedCategory.name;
    },
    closeModal() {
      this.modal.status = false;
    },
    appendCategory(dataCategory) {
      this.dataProductCategories.push(dataCategory);
    },
    tambahCategory: function () {
      axios
        .post(this.urlCategory, this.category, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          this.appendCategory(response.data.data);

          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editCategory: function () {
      axios
        .put(this.urlCategory + "/" + this.category.id, this.category, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          var _this = this;

          _this.dataProductCategories.map(function (category, index) {
            if (category.id == response.data.data.id) {
              _this.dataProductCategories[index] = {
                id: response.data.data.id,
                name: response.data.data.name,
              };
            }

            return category;
          });
          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteCategory: function (categoryId) {
      if (
        confirm(
          "Anda yakin ingin menghapus item ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlCategory + "/" + categoryId, {
            headers: { Authorization: this.authToken },
          })
          .then(
            (response) => {
              this.dataProductCategories = this.dataProductCategories.filter(
                (category) => category.id !== categoryId
              );
              sweatalert.showAlert("success", response.data.message);
            },
            (err) => {
              console.log(err.response);
            }
          )
          .catch((error) => {
            this.errors = error.response.data.errors;

            Object.keys(this.errors).forEach((key) => {
              sweatalert.showAlert("error", this.errors[key]);
            });
          });
      }
    },
  },
};
</script>
