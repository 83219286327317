<style scoped>
.dropdown-menu.show {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-23.6px, 110px, 0px);
  background-color: #d8d8d8;
}
</style>
<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-header">
            <form
              :action="url.urlGetSaleProducts + '/generate'"
              @submit="checkForm()"
              method="POST"
              target="_blank"
              enctype="multipart/form-data"
            >
              <input type="text" hidden name="data" :value="data.dataSubmit" />
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <h6 class="mb-0">Laporan Produk</h6>
                </div>
                <div class="col-12 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Tipe Laporan</label>
                    <select
                      class="form-select"
                      style="max-width: 500px"
                      aria-label="Tipe"
                      v-model="data.type"
                    >
                      <!-- @change="getDataSaleProducts()" -->
                      <option
                        :value="type.id"
                        v-for="type in data.types"
                        :key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 col-xs-3" v-if="data.type == 1">
                  <div class="form-group">
                    <label class="form-label mb-0"
                      >Kode/Nama/Barcode Produk</label
                    >
                    <div class="d-flex justify-content-between">
                      <input
                        type="text"
                        class="form-control rounded-start"
                        list="products"
                        id="exampleDataList"
                        placeholder="Type to search..."
                        autocomplete="off"
                        @input="
                          searchProduct();
                          selectProduct();
                        "
                        v-model="data.searchValue"
                        style="width: 80%; border-radius: 0"
                      />
                      <datalist id="products">
                        <option
                          :value="
                            product.code +
                            ' | ' +
                            product.barcode +
                            ' | ' +
                            product.name
                          "
                          v-for="product in data.productOptions"
                          :key="product.id"
                        ></option>
                      </datalist>
                      <soft-button
                        color="secondary"
                        class="rounded-end"
                        size="sm"
                        variant="outline"
                        type="button"
                        @click="openModalProduct"
                        style="width: 20%; padding: initial; border-radius: 0"
                      >
                        <search />
                      </soft-button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Lokasi</label>
                    <select
                      class="form-select"
                      aria-label="Lokasi"
                      v-model="data.location"
                    >
                      <!-- @change="getDataSaleProducts()" -->
                      <option value="" selected>Semua Lokasi</option>
                      <option
                        :value="location.id"
                        v-for="location in data.locations"
                        :key="location.id"
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Tanggal</label>
                    <Datepicker
                      v-model="data.date"
                      range
                      multiCalendars
                    ></Datepicker>
                    <!-- @update:modelValue="getDataSaleProducts()" -->
                  </div>
                </div>
                <div
                  class="col-12 d-flex align-items-center justify-content-between"
                >
                  <soft-button
                    type="button"
                    color="success"
                    size="sm"
                    variant="outline"
                    @click="getDataSaleProducts()"
                    >Tampilkan</soft-button
                  >
                  <a
                    href="#"
                    class="p-0 nav-link"
                    :class="[showMenu ? 'show' : '']"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="showMenu = !showMenu"
                  >
                    <soft-button color="info" size="sm" variant="outline"
                      >Cetak</soft-button
                    >
                  </a>
                  <ul
                    class="px-2 py-3 dropdown-menu dropdown-menu-end"
                    :class="showMenu ? 'show' : ''"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li class="mb-2">
                      <button
                        type="submit"
                        class="dropdown-item border-radius-md"
                        @focus="printType = 'pdf'"
                      >
                        <div class="py-1 d-flex">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="mb-1 text-sm font-weight-normal">
                              <span class="font-weight-bold">PDF</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button
                        type="submit"
                        class="dropdown-item border-radius-md"
                        @focus="printType = 'excel'"
                      >
                        <div class="py-1 d-flex">
                          <div
                            class="d-flex flex-column justify-content-center"
                          >
                            <h6 class="mb-1 text-sm font-weight-normal">
                              <span class="font-weight-bold">Excel</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body p-0 w-100">
            <div class="table-responsive p-0">
              <table-detail
                v-bind:sales="this.data.sales"
                v-if="data.type == 1"
              >
              </table-detail>
              <table-total v-bind:sales="this.data.sales" v-if="data.type == 2">
              </table-total>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    v-if="data.products"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-xl"
    style="z-index: 100000"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-9 align-items-center">
                <h5 class="mb-0">{{ modal.title }}</h5>
              </div>
              <div class="col-3 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <good-table-products v-bind:products="this.data.products">
                  </good-table-products>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableTotal from "./components/GoodTableTotals";
import TableDetail from "./components/GoodTableDetails";
import GoodTableProducts from "./components/GoodTableProducts.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Modal from "@/views/components/Modal.vue";
import moment from "moment";
import Search from "@/components/Icon/Search.vue";
// import TableSales from "./components/TableSales";

export default {
  name: "report-sales",
  components: {
    SoftButton,
    TableTotal,
    TableDetail,
    Datepicker,
    Modal,
    GoodTableProducts,
    Search,
    // TableSales,
  },
  data() {
    return {
      data: {
        type: 1,
        types: [
          { id: 1, name: "Laporan RPP Detail" },
          { id: 2, name: "Laporan RPP Total" },
        ],
        sales: [],
        location: null,
        locations: [],
        date: null,
        products: [],
        productOptions: [],
        searchValue: "",
        product: {
          id: null,
          code: null,
          name: null,
        },
        dataSubmit: null,
      },
      showMenu: false,
      printType: null,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
      },
      url: {
        urlGetLocations: `${this.apiUrl}/location`,
        urlGetSaleProducts: `${this.apiUrl}/reports/products`,
        urlGetProducts: `${this.apiUrl}/product`,
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataLocation();
    this.getDataProduct();
    // this.getDataSales();
  },
  methods: {
    openModalProduct: function () {
      this.modal.status = true;
      this.modal.title = `Daftar Produk`;
    },
    getDataLocation: function () {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;

          this.data.location = this.data.locations[0].id;

          this.getDataSaleProducts();
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProduct: function () {
      console.log("hoam");
      axios
        .get(this.url.urlGetProducts, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.products = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataSaleProducts: function () {
      if (!this.data.product.id) this.data.searchValue = "";

      const data = {
        type: this.data.type,
        product: this.data.product ? this.data.product.id : null,
        location: this.data.location,
        date: this.data.date
          ? [
              moment(this.data.date[0]).format("YYYY-MM-DD"),
              this.data.date[1]
                ? moment(this.data.date[1]).format("YYYY-MM-DD")
                : null,
            ]
          : null,
      };

      axios
        .get(this.url.urlGetSaleProducts + `?data=${JSON.stringify(data)}`, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.sales = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    searchProduct: function () {
      this.data.productOptions = [];
      const reg = new RegExp(this.searchValue);
      this.data.products.forEach((element) => {
        if (
          element.code.toLowerCase().match(reg) ||
          element.barcode.toLowerCase().match(reg) ||
          element.name.toLowerCase().match(reg)
        ) {
          this.data.productOptions.push(element);
        }
      });
    },
    selectProduct: function () {
      this.data.product = {
        id: null,
        code: null,
        name: null,
      };

      let product = this.data.products.find((item) => {
        return (
          item.code + " | " + item.barcode + " | " + item.name ===
          this.data.searchValue
        );
      });

      if (product) {
        this.data.product.id = product.id;
        this.data.product.code = product.code;
        this.data.product.name = product.name;

        // this.data.searchValue = "";
      }

      // this.getDataSaleProducts();
    },
    checkForm() {
      const data = {
        print_type: this.printType,
        type: this.data.type,
        product: this.data.product ? this.data.product.id : null,
        location: this.data.location,
        date: this.data.date
          ? [
              moment(this.data.date[0]).format("YYYY-MM-DD"),
              this.data.date[1]
                ? moment(this.data.date[1]).format("YYYY-MM-DD")
                : null,
            ]
          : null,
      };
      // const data = {
      //   print_type: this.printType,
      //   type: this.data.type,
      //   location: this.data.location,
      //   date: this.data.date
      //     ? [
      //         moment(this.data.date[0]).format("YYYY-MM-DD"),
      //         this.data.date[1]
      //           ? moment(this.data.date[1]).format("YYYY-MM-DD")
      //           : null,
      //       ]
      //     : null,
      // };

      this.data.dataSubmit = JSON.stringify(data);
    },
  },
};
</script>
