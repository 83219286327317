<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-body pb-2">
            <form
              ref="form"
              action="#"
              method="post"
              role="form"
              v-on:submit.prevent="submit()"
            >
              <div class="row border-bottom">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label">Search By</label
                        ><VueMultiselect
                          v-model="type"
                          value="id"
                          :options="[
                            { id: 1, name: 'Invoice' },
                            { id: 2, name: 'Produk' },
                          ]"
                          :show-labels="false"
                          track-by="name"
                          label="name"
                          :allow-empty="false"
                          @select="changeType()"
                          @input="changeType()"
                        >
                          <!-- @change="changeType()"
                          @select="changeType()"
                          @click="changeType()" -->
                        </VueMultiselect>
                        <!-- <select
                          class="form-select"
                          aria-label="Search by"
                          v-model="type"
                          @change="changeType"
                        >
                          <option value="1">Invoice</option>
                          <option value="2">Produk</option>
                        </select> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label">Search Value</label>
                        <VueMultiselect
                          v-model="searchValueType"
                          :options="
                            type.id == 1 ? salesOptions : productOptions
                          "
                          :show-labels="false"
                          track-by="name"
                          label="name"
                          @select="searchInvoice()"
                          @input="searchInvoice()"
                        >
                        </VueMultiselect>
                        <!-- <input
                          type="text"
                          class="form-control"
                          list="search_list"
                          placeholder="Type to search..."
                          autocomplete="off"
                          @input="searchInvoice()"
                          v-model="searchValueType"
                        />
                        <datalist id="search_list">
                          <template v-if="type == 1">
                            <option
                              :value="
                                invoice.invoice + ' | ' + invoice.customer
                              "
                              v-for="invoice in sales"
                              :key="invoice.id"
                            ></option>
                          </template>
                          <template v-if="type == 2">
                            <option
                              :value="product.code + ' | ' + product.name"
                              v-for="product in productOptions"
                              :key="product.id"
                            ></option>
                          </template>
                        </datalist> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="form-label">Nomor Invoice</label>
                    <input
                      type="text"
                      class="form-control"
                      list="invoices"
                      placeholder="Type to search..."
                      autocomplete="off"
                      @input="selectSale()"
                      v-model="searchValue"
                    />
                    <datalist id="invoices">
                      <option
                        :value="invoice.invoice + ' | ' + invoice.customer"
                        v-for="invoice in salesOptions"
                        :key="invoice.id"
                      ></option>
                    </datalist>
                  </div>
                </div> -->
                <div class="col-12 col-md-4" v-if="searchValueType && invoice">
                  <div class="form-group">
                    <label class="form-label">Nomor Invoice</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="invoice.invoice"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="searchValueType && invoice">
                  <div class="form-group">
                    <label class="form-label">Tanggal Transaksi</label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Nama Produk"
                      v-model="invoice.date"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="searchValueType && invoice">
                  <div class="form-group">
                    <label class="form-label">Kasir</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Admin"
                      v-model="invoice.cashier"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div
                class="mt-2"
                v-if="
                  searchValueType && saleList.length > 0 && !selectedInvoice
                "
              >
                <label class="form-label mb-0">Daftar Invoice</label>
                <div class="table-responsive p-0">
                  <table
                    class="table align-items-center justify-content-center mb-0"
                  >
                    <thead>
                      <tr class="text-secondary text-xs">
                        <th class="text-uppercase font-weight-bolder px-3">
                          No Invoice
                        </th>
                        <th class="text-uppercase font-weight-bolder px-3">
                          Tanggal
                        </th>
                        <th class="text-uppercase font-weight-bolder px-3">
                          Pelanggan
                        </th>
                        <th class="text-uppercase font-weight-bolder px-3">
                          Total Pembelian
                        </th>
                        <th class="w-px-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="sale in saleList" :key="sale.id">
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-xs">
                                {{ sale.invoice }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-xs">
                                {{ formatDate(sale.date) }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-xs">
                                {{ sale.customer }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-xs">
                                {{ formatNumber(sale.total) }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-xs">
                                <soft-button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  variant="outline"
                                  class="text-lowercase px-2"
                                  @click="
                                    searchValue = `${sale.invoice} | ${sale.customer}`;
                                    selectedInvoice = true;
                                    selectSale(sale.id);
                                  "
                                  >pilih</soft-button
                                >
                              </h6>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3" v-if="searchValueType && invoice">
                  <label class="form-label mb-0">Daftar Produk</label>
                  <div class="table-responsive p-0">
                    <table
                      class="table align-items-center justify-content-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Kode Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Nama Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Pembelian
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Harga
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Total Retur
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Jumlah Retur
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="detail in invoice.details" :key="detail.id">
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ detail.item.code }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ detail.item.name }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ parseInt(detail.quantity) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(detail.price) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ parseInt(detail.quantity_return) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <input
                                  type="number"
                                  style="max-width: 100px"
                                  min="0"
                                  class="form-control"
                                  placeholder="Jumlah"
                                  @input="calculateTotal()"
                                  v-model="detail.qty_return"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(detail.total) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="background-color: #c0f3ff">
                          <td
                            colspan="5"
                            class="text-uppercase font-weight-bolder text-end"
                          >
                            Total
                          </td>
                          <td
                            class="text-uppercase font-weight-bolder text-center"
                          >
                            {{ invoice.qty_return }}
                          </td>
                          <td class="text-uppercase font-weight-bolder">
                            {{ formatNumber(invoice.total) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row my-3" v-if="searchValueType && invoice">
                <div class="col-12 d-flex justify-content-between">
                  <soft-button
                    color="warning"
                    size="sm"
                    variant="outline"
                    @click="
                      invoice = null;
                      selectedInvoice = null;
                    "
                  >
                    Batal
                  </soft-button>
                  <soft-button
                    color="success"
                    size="sm"
                    variant="outline"
                    :disable="disable"
                  >
                    <span
                      v-if="disable"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ disable ? " Loading..." : "Buat Transaksi" }}
                  </soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import SoftButton from "@/components/SoftButton.vue";
import moment from "moment";
import VueMultiselect from "vue-multiselect";
// import Select2 from "vue3-select2-component";

export default {
  name: "add-sale-return",
  components: {
    SoftButton,
    VueMultiselect,
    // Select2,
  },
  data() {
    return {
      invoice: null,
      sales: [],
      saleList: [],
      salesOptions: [],
      searchValue: "",
      searchValueType: "",
      disable: false,
      productOptions: [],
      type: { id: 1, name: "Invoice" },
      selectedInvoice: false,
      value: null,
      // options: ["list", "of", "options"],
      url: {
        urlGetProducts: `${this.apiUrl}/product`,
        urlSubmit: `${this.apiUrl}/sale/return`,
        urlGetSales: `${this.apiUrl}/sale`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataSale();
    this.getDataProduct();
  },
  methods: {
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getDataProduct: function () {
      axios
        .get(this.url.urlGetProducts, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          // this.productOptions = res.data.data;
          res.data.data.forEach((element) => {
            this.productOptions.push({
              id: element.id,
              name: element.code + " | " + element.name,
            });
          });
          // res.data.data.e;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    submit: function () {
      if (
        confirm(
          "Anda yakin ingin membuat transaksi ini? Ini tidak dapat dibatalkan."
        )
      ) {
        this.disable = true;
        axios
          .post(this.url.urlSubmit, this.invoice, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            sweatalert.showAlert("success", response.data.message);
            this.$router.push({ name: "Retur Penjualan" });
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
            Object.keys(this.errors).forEach((key) => {
              sweatalert.showAlert("error", this.errors[key]);
            });
            this.disable = false;
          });
      }
    },
    getDataSale: function () {
      axios
        .get(this.url.urlGetSales + "?not_formatted=1", {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.sales = res.data.data;
          res.data.data.forEach((element) => {
            this.salesOptions.push({
              id: element.id,
              name: element.invoice + " | " + (element.customer ?? "-"),
            });
          });
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    // searchSale: function () {
    //   this.salesOptions = [];
    //   const reg = new RegExp(this.searchValue);
    //   this.sales.forEach((element) => {
    //     if (
    //       element.invoice.toLowerCase().match(reg) ||
    //       element.customer.toLowerCase().match(reg)
    //     ) {
    //       this.salesOptions.push(element);
    //     }
    //   });
    // },
    // selectSale: function (saleId) {
    //   this.$nextTick(() => {
    //     let invoice = this.sales.find((item) => {
    //       if (this.type.id == 1) return item.id === this.searchValueType.id;
    //       else return item.id === saleId;
    //     });
    //     if (invoice) {
    //       this.invoice = invoice;
    //       this.invoice.details.forEach((element) => {
    //         element.qty_return = 0;
    //       });
    //     }
    //   });
    // },
    selectSale: function (saleId) {
      this.$nextTick(() => {
        axios
          .get(this.url.urlGetSales + `/${saleId}`, {
            headers: { Authorization: this.authToken },
          })
          .then((res) => {
            console.log(res.data.data);
            this.invoice = res.data.data;
            this.invoice.details.forEach((element) => {
              element.qty_return = 0;
            });
          })
          .catch((error) => {
            sweatalert.showAlert("error", error);
          });
        // let invoice = this.sales.find((item) => {
        //   if (this.type.id == 1) return item.id === this.searchValueType.id;
        //   else return item.id === saleId;
        // });
        // if (invoice) {
        //   this.invoice = invoice;
        //   this.invoice.details.forEach((element) => {
        //     element.qty_return = 0;
        //   });
        // }
      });
    },
    calculateTotal: function () {
      let total = 0;
      let qtyReturn = 0;
      this.invoice.details.forEach((element) => {
        if (element.qty_return > element.quantity - element.quantity_return) {
          element.qty_return = 0;
          sweatalert.showAlert("error", "Stok produk melebihi yang dibeli");
        }

        element.total = element.qty_return * element.price;
        total += element.total;
        qtyReturn += element.qty_return;
      });
      this.invoice.total = total;
      this.invoice.qty_return = qtyReturn;
    },
    formatNumber: function (number) {
      if (number)
        return (
          "Rp" +
          parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        );

      return "Rp0,00";
    },
    changeType() {
      console.log("masuk");
      console.log(this.type);
      this.searchValueType = "";
      this.saleList = [];
      this.invoice = null;
      this.selectedInvoice = false;
    },
    searchInvoice() {
      this.selectedInvoice = null;
      this.saleList = [];
      this.invoice = null;

      if (this.type.id == 1) {
        // this.searchValue = this.searchValueType;
        // this.selectSale();
        this.$nextTick(() => {
          this.selectSale(this.searchValueType.id);
        });
      }
      if (this.type.id == 2) {
        // let product = this.productOptions.find((item) => {
        //   return item.code + " | " + item.name === this.searchValueType;
        // });

        // if (product)
        this.$nextTick(() => {
          if (this.searchValueType) {
            axios
              .get(
                this.url.urlGetSales +
                  `?not_formatted=1&product_id=${this.searchValueType.id}`,
                {
                  headers: { Authorization: this.authToken },
                }
              )
              .then((res) => {
                console.log(res.data.data);
                this.saleList = res.data.data;
              })
              .catch((error) => {
                sweatalert.showAlert("error", error);
              });

            // this.saleList = this.sales.filter((sale) =>
            //   sale.details.some(
            //     (detail) => detail.item.id === this.searchValueType.id
            //   )
            // );
          }
        });
      }
    },
  },
};
</script>
