<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-purchases v-bind:purchases="data.purchases" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TablePurchases from "./components/TablePurchases";

export default {
  name: "purchases",
  components: {
    TablePurchases,
  },
  data() {
    return {
      data: {
        purchases: [],
      },
      url: {
        urlGetPurchases: `${this.apiUrl}/purchase`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataPurchases();
  },
  methods: {
    getDataPurchases: function () {
      axios
        .get(this.url.urlGetPurchases, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.purchases = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
