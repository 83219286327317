<template>
  <div class="py-4 container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-lg-12 col-sm-12">
        <nav-pill />
      </div>
    </div>
    <div class="mt-4">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <table-categories v-bind:productCategories="data.productCategories" />
        </div>
        <div
          class="tab-pane fade"
          id="pills-sub-categories"
          role="tabpanel"
          aria-labelledby="pills-sub-categories-tab"
        >
          <table-sub-categories
            v-bind:productCategories="data.productCategories"
            v-bind:productSubCategories="data.productSubCategories"
          />
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <table-commisions
            v-bind:productCommissions="data.productCommissions"
          />
        </div>
        <div
          class="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
        >
          <table-brands v-bind:productBrands="data.productBrands" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import { sweatalert } from "@/reactivities/sweatalert.js";
import axios from "axios";
import NavPill from "@/views/components/NavPill.vue";
import TableCategories from "./components/TableCategories";
import TableSubCategories from "./components/TableSubCategories";
import TableCommisions from "./components/TableCommisions";
import TableBrands from "./components/TableBrands";

export default {
  name: "Categories",
  components: {
    NavPill,
    TableSubCategories,
    TableCategories,
    TableCommisions,
    TableBrands,
  },
  data() {
    return {
      data: {
        productCategories: [],
        productSubCategories: [],
        productCommissions: [],
        productBrands: [],
      },
      url: {
        urlGetProductCategoris: `${this.apiUrl}/product/category`,
        urlGetProductSubCategoris: `${this.apiUrl}/product/sub-category`,
        urlGetCommissions: `${this.apiUrl}/product/commission`,
        urlGetBrands: `${this.apiUrl}/product/brand`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataProductCategories();
    this.getDataProductSubCategories();
    this.getDataCommissions();
    this.getDataBrands();
  },
  methods: {
    getDataProductCategories: function () {
      axios
        .get(this.url.urlGetProductCategoris, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productCategories = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProductSubCategories: function () {
      axios
        .get(this.url.urlGetProductSubCategoris, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productSubCategories = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataCommissions: function () {
      axios
        .get(this.url.urlGetCommissions, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productCommissions = res.data.data;
        });
    },
    getDataBrands: function () {
      axios
        .get(this.url.urlGetBrands, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productBrands = res.data.data;
        });
    },
  },
};
</script>
