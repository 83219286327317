<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Pengguna</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="openModalCreate()"
            >Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <good-table-users v-bind:users="this.dataUsers"> </good-table-users>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Nama</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama"
                    v-model="user.name"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    v-model="user.email"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Lokasi</label>
                  <select
                    class="form-select"
                    aria-label="Lokasi"
                    v-model="user.location"
                  >
                    <option value="" selected>Pilih lokasi</option>
                    <option
                      :value="location.id"
                      v-for="location in locations"
                      :key="location.id"
                    >
                      {{ location.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Role</label>
                  <select
                    class="form-select"
                    aria-label="Role"
                    v-model="user.role"
                  >
                    <option value="" selected>Pilih role</option>
                    <option
                      :value="role.id"
                      v-for="role in roles"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    v-model="user.password"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Konfirmasi Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Konfirmasi Password"
                    v-model="user.password_confirmation"
                  />
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableUsers from "./GoodTableUsers.vue";

export default {
  props: ["users", "locations", "roles"],
  name: "table-users",
  components: {
    SoftButton,
    Modal,
    GoodTableUsers,
  },
  data() {
    return {
      dataUsers: this.users,
      urlUser: `${this.apiUrl}/user`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahUser",
      },
      user: {
        id: "",
        name: "",
        email: "",
        location: "",
        role: "",
        password: "",
        password_confirmation: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataUsers.length == 0) {
      this.dataUsers = this.users;
    }
  },
  methods: {
    submit(method) {
      if (method == "POST") {
        this.tambahUser();
      } else if (method == "PUT") {
        this.editUser();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahUser";
      this.modal.title = "Tambah Pengguna";
      this.user.id = "";
      this.user.name = "";
      this.user.email = "";
      this.user.location = "";
      this.user.role = "";
      this.user.password = "";
      this.user.password_confirmation = "";
    },
    openModalEdit(userId) {
      this.errors = "";

      const selectedUser = this.users.find((user) => user.id === userId);

      this.modal.status = true;
      this.modal.method = "PUT";
      this.modal.methodFuction = "updateUser";
      this.modal.title = "Edit Pengguna";
      this.modal.button = "Simpan";
      this.user.id = selectedUser.id;
      this.user.name = selectedUser.name;
      this.user.email = selectedUser.email;
      this.user.location = selectedUser.location_id;
      this.user.role = selectedUser.role_id;
      this.user.password = "";
      this.user.password_confirmation = "";
    },
    appendUser(dataUser) {
      this.dataUsers.push(dataUser);
    },
    tambahUser: function () {
      axios
        .post(this.urlUser, this.user, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          this.appendUser(response.data.data);

          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editUser: function () {
      axios
        .put(this.urlUser + "/" + this.user.id, this.user, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.closeModal();

          var _this = this;

          _this.dataUsers.map(function (user, index) {
            if (user.id == response.data.data.id) {
              _this.dataUsers[index] = {
                id: response.data.data.id,
                name: response.data.data.name,
                email: response.data.data.email,
                location_id: response.data.data.location_id,
                location: response.data.data.location,
                role_id: response.data.data.role_id,
                role: response.data.data.role,
              };
            }

            return user;
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteUser: function (userId) {
      if (
        confirm(
          "Anda yakin ingin menghapus produk ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlUser + "/" + userId, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            this.dataUsers = this.dataUsers.filter(
              (user) => user.id !== userId
            );
            sweatalert.showAlert("success", response.data.message);
          });
      }
    },
    closeModal() {
      this.modal.status = false;
    },
  },
};
</script>
