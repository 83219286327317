<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item cursor-pointer">
        <a
          class="nav-link mb-0 px-0 py-4 active active"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          aria-controls="pills-home"
          role="tab"
          aria-selected="true"
          >Kategori Produk</a
        >
      </li>
      <li class="nav-item cursor-pointer">
        <a
          class="nav-link mb-0 px-0 py-4"
          data-bs-toggle="pill"
          data-bs-target="#pills-sub-categories"
          aria-controls="pills-sub-categories"
          role="tab"
          aria-selected="true"
          >Sub Kategori Produk</a
        >
      </li>
      <li class="nav-item cursor-pointer">
        <a
          class="nav-link mb-0 px-0 py-4"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          aria-controls="pills-profile"
          role="tab"
          aria-selected="true"
          >Kategori Komisi</a
        >
      </li>
      <li class="nav-item cursor-pointer">
        <a
          class="nav-link mb-0 px-0 py-4"
          data-bs-toggle="pill"
          data-bs-target="#pills-contact"
          aria-controls="pills-contact"
          role="tab"
          aria-selected="true"
          >Brand</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "nav-pill",
  mounted() {
    setNavPills();
  },
};
</script>
