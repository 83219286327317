import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Categories from "@/views/pages/categories/Categories.vue";
import Products from "@/views/pages/products/Products.vue";
import ProductList from "@/views/pages/products/ProductList.vue";
import Locations from "@/views/pages/locations/Locations.vue";
import Users from "@/views/pages/users/Users.vue";
import Purchases from "@/views/pages/purchases/Purchases.vue";
import AddPurchase from "@/views/pages/purchases/AddPurchase.vue";
import EditPurchase from "@/views/pages/purchases/EditPurchase.vue";
import PurchaseReturns from "@/views/pages/purchases/returns/PurchaseReturns.vue";
import AddPurchaseReturns from "@/views/pages/purchases/returns/AddPurchaseReturns.vue";
import Sales from "@/views/pages/sales/Sales.vue";
import AddSale from "@/views/pages/sales/AddSale.vue";
import EditSale from "@/views/pages/sales/EditSale.vue";
import SaleReturns from "@/views/pages/sales/returns/SaleReturns.vue";
import AddSaleReturns from "@/views/pages/sales/returns/AddSaleReturns.vue";
import Prices from "@/views/pages/prices/Prices.vue";
import ReportSales from "@/views/pages/reports/sales/Sales.vue";
import ReportProducts from "@/views/pages/reports/products/Products.vue";
import { isAuthenticated } from "@/middleware/index";
import { isHub } from "@/middleware/hub";
import middlewarePipeline from "@/middleware/middlewarePipeline";

import authRoutes from "@/router/auth";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/product-list",
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/categories",
    name: "Kategori",
    component: Categories,
    meta: {
      middleware: [isAuthenticated, isHub],
    },
  },
  {
    path: "/products",
    name: "Produk",
    component: Products,
    meta: {
      middleware: [isAuthenticated, isHub],
    },
  },
  {
    path: "/products/prices",
    name: "Harga Khusus",
    component: Prices,
    meta: {
      middleware: [isAuthenticated, isHub],
    },
  },
  {
    path: "/product-list",
    name: "Daftar Produk",
    component: ProductList,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/locations",
    name: "Lokasi",
    component: Locations,
    meta: {
      middleware: [isAuthenticated, isHub],
    },
  },
  {
    path: "/users",
    name: "Pengguna",
    component: Users,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/purchases",
    name: "Pembelian",
    component: Purchases,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/purchases/add",
    name: "Tambah Pembelian",
    component: AddPurchase,
    meta: {
      middleware: [isAuthenticated, isHub],
    },
  },
  {
    path: "/purchases/:id/edit",
    name: "Ubah Pembelian",
    component: EditPurchase,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/purchases/returns",
    name: "Retur Pembelian",
    component: PurchaseReturns,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/purchases/returns/add",
    name: "Tambah Retur Pembelian",
    component: AddPurchaseReturns,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/sales",
    name: "Penjualan",
    component: Sales,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/sales/add",
    name: "Tambah Penjualan",
    component: AddSale,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/sales/:id/edit",
    name: "Ubah Penjualan",
    component: EditSale,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/sales/returns",
    name: "Retur Penjualan",
    component: SaleReturns,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/sales/returns/add",
    name: "Tambah Retur Penjualan",
    component: AddSaleReturns,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/reports/sales",
    name: "Laporan Penjualan",
    component: ReportSales,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  {
    path: "/reports/products",
    name: "Laporan Produk",
    component: ReportProducts,
    meta: {
      middleware: [isAuthenticated],
    },
  },
  ...authRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    router,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
