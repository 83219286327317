<template>
  <router-link
    data-bs-toggle="collapse"
    class="nav-link mx-0"
    :class="{ active: active }"
    :to="to"
    :aria-expanded="isExpanded ? 'true' : 'false'"
    @click="toggleCollapapse(event)"
    v-bind="$attrs"
  >
    <div
      class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="this.$store.state.isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </router-link>

  <div :id="id" class="collapse" :class="isExpanded ? 'show' : ''">
    <ul class="nav ms-4 ps-3">
      <slot name="tree"></slot>
    </ul>
  </div>
</template>
<script>
export default {
  name: "sidenav-collapse-tree",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    url: {
      type: [String],
    },
    id: {
      type: [String],
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: this.url === this.id.split("_")[0] ? true : false,
      active: this.url === this.id.split("_")[0] ? true : false,
    };
  },
  methods: {
    toggleCollapapse() {
      this.isExpanded = !this.isExpanded;
    },
  },
  watch: {
    url() {
      if (this.url === this.id.split("_")[0]) {
        this.active = true;
        this.isExpanded = true;
      } else {
        this.active = false;
        this.isExpanded = false;
      }
    },
  },
};
</script>
