<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="login">
                    <label>Email</label>
                    <input
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      v-model="email"
                      class="form-control"
                    />
                    <label>Password</label>
                    <input
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      v-model="password"
                      class="form-control"
                    />
                    <div class="text-center">
                      <soft-button
                        type="submit"
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >Masuk
                      </soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import { storeAuth } from "@/reactivities/storeAuth.js";
// import TokenService from "@/services/token.service";
import { mapMutations } from "vuex";
import { sweatalert } from "@/reactivities/sweatalert.js";

export default {
  name: "SignIn",
  components: {
    SoftButton,
  },
  data() {
    return {
      email: "",
      password: "",
      remeberme: true,
      submitted: false,
      storeAuth,
      urlPost: `${this.apiUrl}/login`,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login: function () {
      axios
        .post(this.urlPost, {
          email: this.email,
          password: this.password,
        })
        .then(
          (res) => {
            if (res) {
              localStorage.setItem(
                "user",
                JSON.stringify({
                  accessToken: res.data.token,
                  name: res.data.data.name,
                  full_name: res.data.data.full_name,
                  first_name: res.data.data.first_name,
                  last_name: res.data.data.last_name,
                  email: res.data.data.email,
                  role: res.data.data.role_id,
                  type: res.data.data.location.type,
                  ecsys: res.data.data.ecsys,
                })
              );

              storeAuth.isLogin = true;
              storeAuth.accessToken = "Bearer " + res.data.token;
              storeAuth.user.full_name = res.data.data.full_name;
              sweatalert.showAlert("success", res.data.message);

              setTimeout(
                () => this.$router.push({ name: "Daftar Produk" }),
                2000
              );
            }
          },
          (err) => {
            console.log(err);
          }
        );
    },
  },
};
</script>
