<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-sales v-bind:sales="data.sales" />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableSales from "./components/TableSales";

export default {
  name: "sales",
  components: {
    TableSales,
  },
  data() {
    return {
      data: {
        sales: [],
      },
      url: {
        urlGetSales: `${this.apiUrl}/sale`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataSales();
  },
  methods: {
    getDataSales: function () {
      axios
        .get(this.url.urlGetSales, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.sales = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
