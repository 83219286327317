<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Produk</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="downloadBarcode()"
            class="mx-3"
            >Cetak Barcode</soft-button
          >
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="openModalCreate()"
            >Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <table-list v-bind:products="this.dataProducts"> </table-list>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Kode Produk</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Kode Produk"
                    v-model="product.code"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Nama Produk</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama Produk"
                    v-model="product.name"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Harga Jual</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Harga Jual"
                    v-model="product.price"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Kategori Produk</label>
                  <select
                    class="form-select"
                    aria-label="Kategori produk"
                    v-model="product.category"
                    @change="displaySubCategory"
                  >
                    <option value="" selected>Pilih kategori produk</option>
                    <option
                      :value="category.id"
                      v-for="category in productCategories"
                      :key="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Sub Kategori Produk</label>
                  <select
                    class="form-select"
                    aria-label="Kategori produk"
                    v-model="product.sub_category"
                  >
                    <option value="" selected>Pilih Sub kategori produk</option>
                    <option
                      :value="subCategory.id"
                      v-for="subCategory in subCategory"
                      :key="subCategory.id"
                    >
                      {{ subCategory.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Merek</label>
                  <select
                    class="form-select"
                    aria-label="Merek"
                    v-model="product.brand"
                  >
                    <option value="" selected>Pilih merek</option>
                    <option
                      :value="brand.id"
                      v-for="brand in productBrands"
                      :key="brand.id"
                    >
                      {{ brand.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label">Kategori Komisi</label>
                  <select
                    class="form-select"
                    aria-label="Kategori komisi"
                    v-model="product.commission"
                  >
                    <option value="" selected>Pilih komisi</option>
                    <option
                      :value="commission.id"
                      v-for="commission in productCommissions"
                      :key="commission.id"
                    >
                      {{ commission.name }}
                    </option>
                  </select>
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal
    :show="modalBarcode.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-10 d-flex align-items-center">
                <h6 class="mb-0">{{ modalBarcode.title }}</h6>
              </div>
              <div class="col-2 text-end">
                <span
                  class="cursor-pointer"
                  @click="this.modalBarcode.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="col-12">
              <img
                class="img-fluid"
                :src="selectedProductBarcode.barcode_url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableList from "./MyTable";

export default {
  props: [
    "products",
    "productCategories",
    "productSubCategories",
    "productCommissions",
    "productBrands",
  ],
  name: "table-products",
  components: {
    SoftButton,
    TableList,
    Modal,
  },
  data() {
    return {
      dataProducts: this.products,
      urlProduct: `${this.apiUrl}/product`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahProduct",
      },
      modalBarcode: {
        status: false,
        title: "", // default
      },
      product: {
        id: "",
        code: "",
        name: "",
        price: "",
        category: "",
        sub_category: "",
        brand: "",
        commission: "",
      },
      subCategory: [],
      selectedProductBarcode: {
        barcode_url: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataProducts.length == 0) {
      this.dataProducts = this.products;
    }
  },
  methods: {
    displaySubCategory() {
      this.subCategory = [];
      this.subCategory = this.productSubCategories.filter(
        (option) => option.product_category_id == this.product.category
      );
    },
    submit(method) {
      if (method == "POST") {
        this.tambahProduct();
      } else if (method == "PUT") {
        this.editProduct();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahProduct";
      this.modal.title = "Tambah Kategori";
      this.product.id = "";
      this.product.code = "";
      this.product.name = "";
      this.product.price = "";
      this.product.category = "";
      this.product.sub_category = "";
      this.product.brand = "";
      this.product.commission = "";
    },
    openModalEdit(productId) {
      this.errors = "";

      const selectedProduct = this.products.find(
        (product) => product.id === productId
      );

      this.modal.status = true;
      this.modal.method = "PUT";
      this.modal.methodFuction = "updateProduct";
      this.modal.title = "Edit Produk";
      this.modal.button = "Simpan";
      this.product.id = selectedProduct.id;
      this.product.code = selectedProduct.code;
      this.product.name = selectedProduct.name;
      this.product.price = selectedProduct.price;
      this.product.category = selectedProduct.product_category_id;
      this.displaySubCategory();
      this.product.sub_category = selectedProduct.product_sub_category_id;
      this.product.brand = selectedProduct.product_brand_id;
      this.product.commission = selectedProduct.product_commission_id;
    },
    appendProduct(dataProduct) {
      this.dataProducts.push(dataProduct);
    },
    tambahProduct: function () {
      axios
        .post(this.urlProduct, this.product, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          this.appendProduct(response.data.data);

          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editProduct: function () {
      axios
        .put(this.urlProduct + "/" + this.product.id, this.product, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.closeModal();

          var _this = this;

          _this.dataProducts.map(function (product, index) {
            if (product.id == response.data.data.id) {
              _this.dataProducts[index] = {
                id: response.data.data.id,
                code: response.data.data.code,
                name: response.data.data.name,
                price: response.data.data.price,
                price_f: response.data.data.price_f,
                product_category_id: response.data.data.product_category_id,
                product_category: response.data.data.product_category,
                product_sub_category_id:
                  response.data.data.product_sub_category_id,
                product_sub_category: response.data.data.product_sub_category,
                product_brand_id: response.data.data.product_brand_id,
                product_brand: response.data.data.product_brand,
                product_commission_id: response.data.data.product_commission_id,
                product_commission: response.data.data.product_commission,
              };
            }

            return product;
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteProduct: function (productId) {
      if (
        confirm(
          "Anda yakin ingin menghapus produk ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlProduct + "/" + productId, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            this.dataProducts = this.dataProducts.filter(
              (product) => product.id !== productId
            );
            sweatalert.showAlert("success", response.data.message);
          });
      }
    },
    openModalBarcode(productId) {
      console.log("parent");
      this.selectedProductBarcode = this.products.find(
        (product) => product.id === productId
      );
      this.modalBarcode.title = `Barcode ${this.selectedProductBarcode.code} | ${this.selectedProductBarcode.name}`;
      this.modalBarcode.status = true;
    },
    downloadBarcode: function () {
      axios
        .get(`${this.apiUrl}/product/print-barcode`, {
          headers: {
            Authorization: this.authToken,
          },
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Daftar Barcode.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    closeModal() {
      this.modal.status = false;
    },
  },
};
</script>
