<template>
  <div class="card-body p-0 w-100">
    <div class="px-4 pb-2">
      <soft-button
        color="success"
        size="sm"
        variant="outline"
        @click="openModalCreate()"
        >Tambah Baru</soft-button
      >
    </div>
    <div class="table-responsive p-0">
      <good-table-prices v-bind:prices="this.dataPrices"> </good-table-prices>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group" v-if="modal.type != 'edit'">
                  <label class="form-label">Kode/Nama/Barcode Produk</label>
                  <div class="d-flex justify-content-between">
                    <input
                      type="text"
                      class="form-control"
                      list="products"
                      id="exampleDataList"
                      placeholder="Type to search..."
                      autocomplete="off"
                      @input="
                        searchProduct();
                        selectProduct();
                      "
                      v-model="searchValue"
                      style="width: 70%"
                    />
                    <datalist id="products">
                      <option
                        :value="
                          product.code +
                          ' | ' +
                          product.barcode +
                          ' | ' +
                          product.name
                        "
                        v-for="product in productOptions"
                        :key="product.id"
                      ></option>
                    </datalist>
                    <soft-button
                      color="info"
                      size="sm"
                      variant="outline"
                      type="button"
                      @click="openModalProduct"
                      style="width: 20%; padding: initial"
                    >
                      <search />
                    </soft-button>
                  </div>
                </div>
                <div class="form-group mb-1">
                  <label class="form-label">Kode Produk</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Kode Produk"
                    readonly
                    v-model="product.code"
                  />
                </div>
                <div class="form-group mb-1">
                  <label class="form-label">Nama Produk</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama Produk"
                    readonly
                    v-model="product.name"
                  />
                </div>
                <div class="form-group mb-1">
                  <label class="form-label">Harga Umum</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Harga"
                    :value="formatNumber(product.price)"
                    readonly
                  />
                </div>
                <div class="form-group mb-1">
                  <label class="form-label">Harga Khusus</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Harga"
                    v-model="product.special_price"
                  />
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal
    :show="modalProduct.status"
    v-if="products"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-xl"
    style="z-index: 100000"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-9 align-items-center">
                <h5 class="mb-0">{{ modalProduct.title }}</h5>
              </div>
              <div class="col-3 text-end">
                <span
                  class="cursor-pointer"
                  @click="this.modalProduct.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <good-table-products v-bind:products="this.products">
                  </good-table-products>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "@/views/components/Modal.vue";
import SoftButton from "@/components/SoftButton.vue";
import GoodTablePrices from "./GoodTablePrices.vue";
import Search from "@/components/Icon/Search.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableProducts from "./GoodTableProducts.vue";

export default {
  props: ["prices", "products"],
  name: "table-prices",
  components: {
    Modal,
    SoftButton,
    GoodTablePrices,
    GoodTableProducts,
    Search,
  },
  data() {
    return {
      dataPrices: this.prices,
      searchValue: "",
      productOptions: [],
      product: {
        id: "",
        code: "",
        name: "",
        price: "",
        special_price: "",
        location_id: "",
      },
      urlPrice: `${this.apiUrl}/product/price`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahPrice",
        type: "",
      },
      modalProduct: {
        status: false,
        method: "POST", // default
        title: "",
      },
      modalBarcode: {
        status: false,
        title: "", // default
      },
      selectedPriceBarcode: {
        barcode_url: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    this.dataPrices = this.prices;
    if (this.dataPrices.length == 0) {
      this.dataPrices = this.prices;
    }
  },
  methods: {
    openModalProduct: function () {
      this.modalProduct.status = true;
      this.modalProduct.title = `Daftar Produk`;
    },
    submit(method) {
      if (method == "POST") {
        this.tambahPrice();
      } else if (method == "PUT") {
        this.editPrice();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.type = "add";
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahPrice";
      this.modal.title = "Tambah Harga Khusus";
      this.modal.button = "Simpan";
      this.product = {
        id: "",
        code: "",
        name: "",
        price: "",
        special_price: "",
        location_id: "",
      };
    },
    searchProduct: function () {
      this.productOptions = [];
      const reg = new RegExp(this.searchValue);
      this.products.forEach((element) => {
        if (
          element.code.toLowerCase().match(reg) ||
          element.barcode.toLowerCase().match(reg) ||
          element.name.toLowerCase().match(reg)
        ) {
          this.productOptions.push(element);
        }
      });
    },
    selectProduct: function () {
      let product = this.products.find((item) => {
        return (
          item.code + " | " + item.barcode + " | " + item.name ===
          this.searchValue
        );
      });
      if (product) {
        this.product.id = product.id;
        this.product.code = product.code;
        this.product.name = product.name;
        this.product.price = product.price;
        this.product.location_id = this.$parent.data.location;

        this.searchValue = "";
      }
    },
    openModalEdit(productId) {
      this.errors = "";

      const selectedProduct = this.dataPrices.find(
        (product) => product.id === productId
      );

      this.modal.status = true;
      this.modal.type = "edit";
      this.modal.method = "PUT";
      this.modal.methodFuction = "updatePrice";
      this.modal.title = "Edit Harga Khusus";
      this.modal.button = "Simpan";
      this.product.id = selectedProduct.id;
      this.product.code = selectedProduct.code;
      this.product.name = selectedProduct.name;
      this.product.price = selectedProduct.price;
      this.product.special_price = selectedProduct.special_price;
      this.product.location_id = selectedProduct.location_id;
    },
    tambahPrice: function () {
      this.product.item_id = this.product.id;
      axios
        .post(this.urlPrice, this.product, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.modal.status = false;
          this.$parent.getDataProduct();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editPrice: function () {
      axios
        .put(this.urlPrice + "/" + this.product.id, this.product, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.modal.status = false;
          this.$parent.getDataProduct();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteProduct: function (productId) {
      if (
        confirm(
          "Anda yakin ingin menghapus harga khusus produk ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlPrice + "/" + productId, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            sweatalert.showAlert("success", response.data.message);

            this.$parent.getDataProduct();
          });
      }
    },
    formatNumber(number) {
      if (number)
        return (
          "Rp" +
          parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        );

      return "Rp0,00";
    },
  },
};
</script>
