<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Brand Produk</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="openModalCreate()"
            >Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <good-table-brands v-bind:brands="this.dataProductBrands">
        </good-table-brands>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Nama Brand</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama Brand"
                    v-model="brand.name"
                  />
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableBrands from "./GoodTableBrands";

export default {
  props: ["productBrands"],
  name: "table-brand",
  components: {
    SoftButton,
    GoodTableBrands,
    Modal,
  },
  data() {
    return {
      dataProductBrands: this.productBrands,
      listCategories: null,
      urlBrand: `${this.apiUrl}/product/brand`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahBrand",
      },
      brand: {
        id: "",
        name: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataProductBrands.length == 0) {
      this.dataProductBrands = this.productBrands;
    }
  },
  methods: {
    submit(method) {
      if (method == "POST") {
        this.tambahBrand();
      } else if (method == "PUT") {
        this.editBrand();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahBrand";
      this.modal.title = "Tambah Brand";
      this.brand.name = "";
    },
    openModalEdit(brandId) {
      this.errors = "";

      const selectedBrand = this.productBrands.find(
        (brand) => brand.id === brandId
      );

      this.modal.status = true;
      this.modal.method = "PUT";
      this.modal.methodFuction = "updateBrand";
      this.modal.title = "Edit Brand";
      this.modal.button = "Simpan";
      this.brand.id = selectedBrand.id;
      this.brand.name = selectedBrand.name;
    },
    closeModal() {
      this.modal.status = false;
    },
    appendBrand(dataBrand) {
      this.dataProductBrands.push(dataBrand);
    },
    tambahBrand: function () {
      axios
        .post(this.urlBrand, this.brand, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          this.appendBrand(response.data.data);

          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editBrand: function () {
      axios
        .put(this.urlBrand + "/" + this.brand.id, this.brand, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          var _this = this;

          _this.dataProductBrands.map(function (brand, index) {
            if (brand.id == response.data.data.id) {
              _this.dataProductBrands[index] = {
                id: response.data.data.id,
                name: response.data.data.name,
              };
            }

            return brand;
          });
          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteBrand: function (brandId) {
      if (
        confirm(
          "Anda yakin ingin menghapus item ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlBrand + "/" + brandId, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            this.dataProductBrands = this.dataProductBrands.filter(
              (brand) => brand.id !== brandId
            );
            sweatalert.showAlert("success", response.data.message);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;

            Object.keys(this.errors).forEach((key) => {
              sweatalert.showAlert("error", this.errors[key]);
            });
          });
      }
    },
  },
};
</script>
