<template>
  <div class="card-body p-0 w-100">
    <div class="table-responsive p-0">
      <good-table-product-list v-bind:products="this.dataProducts">
      </good-table-product-list>
    </div>
  </div>
  <modal
    :show="modalBarcode.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-10 d-flex align-items-center">
                <h6 class="mb-0">{{ modalBarcode.title }}</h6>
              </div>
              <div class="col-2 text-end">
                <span
                  class="cursor-pointer"
                  @click="this.modalBarcode.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="col-12">
              <img
                class="img-fluid"
                :src="selectedProductBarcode.barcode_url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal
    :show="modal.status"
    v-if="product"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-xl"
    style="z-index: 100000"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-9 align-items-center">
                <h5 class="mb-0">{{ modal.title }}</h5>
              </div>
              <div class="col-3 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <good-table-product-detail
                    v-bind:transactions="product.transactions"
                    v-if="product.transactions"
                  >
                  </good-table-product-detail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
// import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableProductList from "./GoodTableProductList.vue";
import GoodTableProductDetail from "./GoodTableProductDetail.vue";

export default {
  props: ["products"],
  name: "table-product-list",
  components: {
    // SoftButton,
    Modal,
    GoodTableProductList,
    GoodTableProductDetail,
  },
  data() {
    return {
      dataProducts: this.products,
      product: {
        id: "",
        nama: "",
        harga: "",
        stok: "",
        created_at: "",
        updated_at: "",
        transactions: [],
      },
      urlProduct: `${this.apiUrl}/product`,
      urlProductStock: `${this.apiUrl}/product/stock`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahProduct",
      },
      modalBarcode: {
        status: false,
        title: "", // default
      },
      selectedProductBarcode: {
        barcode_url: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    this.dataProducts = this.products;
    if (this.dataProducts.length == 0) {
      this.dataProducts = this.products;
    }
  },
  methods: {
    submit(method) {
      if (method == "POST") {
        this.tambahProduct();
      } else if (method == "PUT") {
        this.editProduct();
      }
    },
    openModalBarcode(productId) {
      this.selectedProductBarcode = this.products.find(
        (product) => product.id === productId
      );
      this.modalBarcode.title = `Barcode ${this.selectedProductBarcode.code} | ${this.selectedProductBarcode.name}`;
      this.modalBarcode.status = true;
    },
    downloadBarcode: function () {
      axios
        .get(`${this.apiUrl}/product/print-barcode`, {
          headers: {
            Authorization: this.authToken,
          },
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Daftar Barcode.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    closeModal() {
      this.modal.status = false;
    },
    openModalDetail: function (id) {
      this.product = this.dataProducts.find((product) => product.id == id);

      axios
        .get(
          this.urlProductStock +
            `/${id}?location=${this.$parent.data.location}`,
          {
            headers: { Authorization: this.authToken },
          }
        )
        .then((res) => {
          this.product.transactions = res.data.data;

          this.modal.status = true;
          this.modal.method = "POST";
          this.modal.title = `Daftar Transaksi ${this.product.name}`;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
