export default ({
    next,
    router
}) => {
    if (!localStorage.getItem(`user`)) {
        router.push({
            name: `Sign In`
        });

        return next();
    }

    return next();
}