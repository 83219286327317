<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Pembelian</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="addPurchases"
          >
            Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <table-list v-bind:purchases="this.dataPurchases"> </table-list>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    v-if="purchase"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-9 align-items-center">
                <h5 class="mb-0">Detail Pembelian</h5>
              </div>
              <div class="col-3 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group text-center">
                  <label class="form-label text-sm mb-0">Nama Kasir</label>
                  <div class="text-sm">{{ purchase.cashier }}</div>
                </div>
                <div class="row border-bottom">
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0">No Invoice</label>
                      <div class="text-sm">{{ purchase.invoice }}</div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0"
                        >Tanggal Invoice</label
                      >
                      <div class="text-sm">{{ purchase.date_f }}</div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0"
                        >Lokasi Tujuan</label
                      >
                      <div class="text-sm">{{ purchase.location }}</div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center mt-3 mb-2">
                  <label class="form-label text-sm mb-0">Daftar Produk</label>
                </div>
                <div class="table-responsive p-0">
                  <table
                    class="table align-items-center justify-content-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Kode
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Nama Produk
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Harga
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Jumlah
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in purchase.details" :key="product.id">
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.item.code }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.item.name }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.price_f }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.quantity }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.total_f }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row my-3">
                  <div class="col-8 text-end">
                    <label class="text-lg">Total</label>
                  </div>
                  <div class="col-4 text-start">
                    <label class="text-lg">{{ purchase.total_f }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer border-top">
            <div class="d-flex justify-content-between">
              <soft-button
                color="secondary"
                size="sm"
                variant="outline"
                @click="printInvoice()"
                >Invoice</soft-button
              >
              <soft-button
                color="secondary"
                size="sm"
                variant="outline"
                @click="printBarcode()"
                >Cetak Barcode</soft-button
              >
              <soft-button
                color="warning"
                size="sm"
                variant="outline"
                @click="editPurchase"
                v-if="purchase.enable_edit"
                >Ubah</soft-button
              >
              <soft-button
                color="danger"
                size="sm"
                variant="outline"
                @click="deletePurchase"
                v-if="purchase.enable_edit"
                >Hapus</soft-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableList from "./MyTable";

export default {
  props: ["purchases"],
  name: "table-purchases",
  components: {
    SoftButton,
    TableList,
    Modal,
  },
  data() {
    return {
      dataPurchases: this.purchases,
      purchase: null,
      url: {
        urlGetPurchase: `${this.apiUrl}/purchase/`,
        urlDelete: `${this.apiUrl}/purchase/`,
      },
      modal: {
        status: false,
        method: "POST",
        title: "",
        button: "Tambah Baru",
        methodFuction: "tambahProduct",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataPurchases.length == 0) {
      this.dataPurchases = this.purchases;
    }
  },
  methods: {
    addPurchases() {
      this.$router.push({ name: "Tambah Pembelian" });
    },
    getPurchase: function (id) {
      axios
        .get(this.url.urlGetPurchase + id, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.purchase = res.data.data;
          this.modal.status = true;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error.response.data.message);
        });
    },
    editPurchase: function () {
      this.$router.push({
        name: "Ubah Pembelian",
        params: { id: this.purchase.id },
      });
    },
    deletePurchase: function () {
      if (
        confirm(
          "Anda yakin ingin menghapus pembelian ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.url.urlDelete + this.purchase.id, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            sweatalert.showAlert("success", response.data.message);

            this.$router.go();
          })
          .catch((error) => {
            sweatalert.showAlert("error", error.response.data.message);
          });
      }
    },
    printBarcode: function () {
      window.open(
        this.url.urlGetPurchase + this.purchase.id + "/print-barcode"
      );
    },
    printInvoice: function () {
      window.open(this.url.urlGetPurchase + this.purchase.id + "/invoice");
    },
    // printBarcode: function () {
    //   const prettyPrintIframe = document.createElement("iframe");

    //   prettyPrintIframe.setAttribute("id", "PrettyPrintFrame");
    //   prettyPrintIframe.setAttribute("name", "PrettyPrintFrame");
    //   prettyPrintIframe.setAttribute("style", "display: none;");

    //   document.body.appendChild(prettyPrintIframe);
    //   prettyPrintIframe.contentWindow.document.head.innerHTML = this.generateHeaderHtml();
    //   prettyPrintIframe.contentWindow.document.body.innerHTML = this.generatePrintLayout();

    //   var imgs = prettyPrintIframe.contentWindow.document.images,
    //     len = imgs.length,
    //     counter = 0;

    //   [].forEach.call(imgs, function (img) {
    //     if (img.complete) incrementCounter();
    //     else img.addEventListener("load", incrementCounter, false);
    //   });

    //   function incrementCounter() {
    //     counter++;
    //     if (counter === len) {
    //       try {
    //         const contentWindow = prettyPrintIframe.contentWindow;
    //         const result = contentWindow.document.execCommand(
    //           "print",
    //           false,
    //           null
    //         );

    //         // if execCommand is unsupported
    //         if (!result) {
    //           contentWindow.print();
    //         }
    //       } catch (e) {
    //         // print fallback
    //         window.frames["PrettyPrintFrame"].focus();
    //         window.frames["PrettyPrintFrame"].print();
    //       }
    //     }
    //   }
    // },
    // generatePrintLayout: function () {
    //   let html = `<div class="add-margin"></div>`;
    //   html += `<div class="row">`;

    //   let count = 0;
    //   this.purchase.details.forEach((detail) => {
    //     count = count + parseInt(detail.quantity);
    //   });

    //   let k = 1;
    //   this.purchase.details.forEach((detail) => {
    //     for (let i = 0; i < detail.quantity; i++) {
    //       html += `<img src="${detail.item.barcode_url}" alt="" />`;
    //       if (k % 5 == 0) {
    //         html += `</div>`;
    //         if (k % 55 == 0 && k < count) {
    //           html += `<div class="page-break"></div> <div class="add-margin"></div> <div class="row">`;
    //         } else html += `<div class="row">`;
    //       }
    //       k++;
    //     }
    //   });

    //   return html;
    // },
    // generateHeaderHtml: function () {
    //   let headerHtml = "";

    //   headerHtml += this.generateGlobalCss();

    //   return headerHtml;
    // },
    // generateGlobalCss: function () {
    //   let css = "<style>";

    //   css +=
    //     "body{width:204mm;height:177mm;margin:0;background-color:#0ff;}.add-margin{height:2mm}img{height:11.8mm;width:auto}img:not(:last-child){margin-right:7.2mm}.row{display:flex;margin-bottom:4.1mm}@media print{body{width:204mm;height:177mm;margin:0}.page-break{page-break-inside:avoid;page-break-before:always}}";

    //   css += "</style>";

    //   return css;
    // },
  },
};
</script>
