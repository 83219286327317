<style>
.w-px-1 {
  width: 1px !important;
  white-space: nowrap;
}
</style>
<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="prices"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span v-if="props.column.field == 'action'"
          ><a
            href="javascript:;"
            class="text-success font-weight-bold text-xs mx-2"
            data-toggle="tooltip"
            data-original-title="Edit user"
            @click="openModalEdit(props.row.id)"
            >Edit</a
          >
          <a
            href="javascript:;"
            class="text-danger font-weight-bold text-xs"
            data-toggle="tooltip"
            data-original-title="Edit user"
            @click="deleteProduct(props.row.sp_id)"
            >Hapus</a
          >
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["prices"],
  name: "good-table-product-list",
  components: {
    // SoftButton,
    VueGoodTable,
  },
  data() {
    return {
      dataProducts: this.prices,
      columns: [
        {
          label: "Nama Produk",
          field: "name",
        },
        {
          label: "Kategori",
          field: "product_category",
        },
        {
          label: "Merek",
          field: "product_brand",
        },
        {
          label: "Harga Jual Umum",
          field: "price_f",
          tdClass: "text-end",
          thClass: "text-end w-px-1",
        },
        {
          label: "Harga Jual Khusus",
          field: "special_price_f",
          tdClass: "text-end",
          thClass: "text-end w-px-1",
        },
        {
          label: "Option",
          field: "action",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataProducts.length == 0) {
      this.dataProducts = this.prices;
    }
  },
  methods: {
    openModalEdit(id) {
      this.$parent.openModalEdit(id);
    },
    deleteProduct: function (id) {
      this.$parent.deleteProduct(id);
    },
  },
};
</script>
