<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="sales"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span v-if="props.column.field == 'total'">
          {{ formatNumber(props.row.total) }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import moment from "moment";

export default {
  props: ["sales"],
  name: "my-table",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "category",
        },
        {
          label: "Total Produk Terjual",
          field: "quantity",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Total",
          field: "total",
          tdClass: "text-end",
          thClass: "text-end",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatNumber(number) {
      if (number)
        return (
          "Rp" +
          parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        );

      return "Rp0,00";
    },
  },
};
</script>
