<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="users"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
      <template #table-row="props">
        <span v-if="props.column.field == 'action'">
          <a
            href="javascript:;"
            class="text-success font-weight-bold text-xs mx-2"
            data-toggle="tooltip"
            data-original-title="Edit user"
            @click="openModalEdit(props.row.id)"
            >Edit</a
          >
          <a
            href="javascript:;"
            class="text-danger font-weight-bold text-xs"
            data-toggle="tooltip"
            data-original-title="Edit user"
            @click="deleteUser(props.row.id)"
            >Hapus</a
          >
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["users"],
  name: "good-table-product-list",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      datausers: this.users,
      columns: [
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Lokasi",
          field: "location",
        },
        {
          label: "Role",
          field: "role",
        },
        {
          label: "",
          field: "action",
        },
      ],
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.datausers.length == 0) {
      this.datausers = this.users;
    }
  },
  methods: {
    openModalEdit(id) {
      this.$parent.openModalEdit(id);
    },
    deleteUser: function (id) {
      this.$parent.deleteUser(id);
    },
  },
};
</script>
