<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <form
              :action="url.urlGetProducts + '/generate'"
              @submit="checkForm()"
              method="POST"
              target="_blank"
              enctype="multipart/form-data"
            >
              <div class="row">
                <div class="col-6 align-items-center">
                  <h6>Daftar Produk</h6>
                </div>
                <div class="col-6 text-end">
                  <div
                    style="
                      max-width: 300px;
                      width: 100%;
                      justify-content: space-between;
                      display: flex;
                      display: inline-flex;
                    "
                  >
                    <input
                      type="text"
                      hidden
                      name="data"
                      :value="data.dataSubmit"
                    /><a
                      href="#"
                      class="p-0 nav-link"
                      :class="[showMenu ? 'show' : '']"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      @click="showMenu = !showMenu"
                    >
                      <soft-button color="info" size="sm" variant="outline"
                        >Cetak</soft-button
                      >
                    </a>
                    <ul
                      class="px-2 py-3 dropdown-menu dropdown-menu-end"
                      :class="showMenu ? 'show' : ''"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li class="mb-2">
                        <button
                          type="submit"
                          class="dropdown-item border-radius-md"
                          @focus="printType = 'pdf'"
                        >
                          <div class="py-1 d-flex">
                            <div
                              class="d-flex flex-column justify-content-center"
                            >
                              <h6 class="mb-1 text-sm font-weight-normal">
                                <span class="font-weight-bold">PDF</span>
                              </h6>
                            </div>
                          </div>
                        </button>
                      </li>
                      <li>
                        <button
                          type="submit"
                          class="dropdown-item border-radius-md"
                          @focus="printType = 'excel'"
                        >
                          <div class="py-1 d-flex">
                            <div
                              class="d-flex flex-column justify-content-center"
                            >
                              <h6 class="mb-1 text-sm font-weight-normal">
                                <span class="font-weight-bold">Excel</span>
                              </h6>
                            </div>
                          </div>
                        </button>
                      </li>
                    </ul>
                    <soft-button
                      type="button"
                      color="success"
                      size="sm"
                      variant="outline"
                      @click="downloadBarcode()"
                      >Cetak Barcode</soft-button
                    >
                  </div>
                </div>
                <div class="col-12 align-items-center">
                  <div class="form-group">
                    <label class="form-label mb-0">Lokasi</label>
                    <select
                      class="form-select"
                      style="max-width: 500px"
                      aria-label="Lokasi"
                      v-model="data.location"
                      @change="getDataProduct()"
                    >
                      <option value="" selected>Pilih Lokasi</option>
                      <option
                        :value="location.id"
                        v-for="location in data.locations"
                        :key="location.id"
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <table-product-list v-bind:products="data.products" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableProductList from "./components/TableProductList";

export default {
  name: "product-list",
  components: { SoftButton, TableProductList },
  data() {
    return {
      data: {
        products: [],
        location: {
          id: "",
        },
        locations: [],
        dataSubmit: null,
      },
      url: {
        urlGetLocations: `${this.apiUrl}/location`,
        urlGetProducts: `${this.apiUrl}/product/stock`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      showMenu: false,
      printType: null,
    };
  },
  mounted() {
    setTooltip();
    this.getDataLocation();
  },
  methods: {
    getDataLocation: function () {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;

          this.data.location = this.data.locations[0].id;

          this.getDataProduct();
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProduct: function () {
      axios
        .get(this.url.urlGetProducts + `?location=${this.data.location}`, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          console.log(this.data.products);
          this.data.products = res.data.data;
          console.log(this.data.products);
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    downloadBarcode: function () {
      axios
        .get(`${this.apiUrl}/product/print-barcode`, {
          headers: {
            Authorization: this.authToken,
          },
          responseType: "blob",
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Daftar Barcode.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    checkForm() {
      const data = {
        print_type: this.printType,
        location: this.data.location,
        user: JSON.parse(localStorage.getItem("user")),
      };

      this.data.dataSubmit = JSON.stringify(data);
    },
  },
};
</script>
