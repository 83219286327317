<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Lokasi Cabang</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="openModalCreate()"
            >Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <good-table-locations v-bind:locations="this.dataLocations">
        </good-table-locations>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-md"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ modal.title }}</h6>
              </div>
              <div class="col-6 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="mb-4 text-danger" v-for="error in errors" :key="error">
              <div v-if="!!error.forEach">
                <p class="mb-0" v-for="message in error" :key="message">
                  {{ message }}
                </p>
              </div>
            </div>
            <form
              ref="form"
              action="#"
              :method="modal.method"
              role="form"
              class="row"
              v-on:submit.prevent="submit(modal.method)"
            >
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Nama Cabang</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama Cabang"
                    v-model="location.name"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Kode Cabang</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Kode Cabang"
                    v-model="location.code"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label">Alamat</label>
                  <textarea
                    class="form-control"
                    placeholder="Alamat"
                    v-model="location.address"
                  />
                </div>
                <div class="my-3">
                  <soft-button color="success" size="sm" variant="outline">{{
                    modal.button
                  }}</soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import { sweatalert } from "@/reactivities/sweatalert.js";
import GoodTableLocations from "./GoodTableLocations.vue";

export default {
  props: ["locations"],
  name: "table-locations",
  components: {
    SoftButton,
    Modal,
    GoodTableLocations,
  },
  data() {
    return {
      dataLocations: this.locations,
      urlLocation: `${this.apiUrl}/location`,
      modal: {
        status: false,
        method: "POST", // default
        title: "",
        button: "Tambah Baru", // default
        methodFuction: "tambahLocation",
      },
      location: {
        id: "",
        code: "",
        name: "",
        address: "",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataLocations.length == 0) {
      this.dataLocations = this.locations;
    }
  },
  methods: {
    submit(method) {
      if (method == "POST") {
        this.tambahLocation();
      } else if (method == "PUT") {
        this.editLocation();
      }
    },
    openModalCreate() {
      this.errors = "";

      this.modal.status = true;
      this.modal.method = "POST";
      this.modal.methodFuction = "tambahLocation";
      this.modal.title = "Tambah Lokasi Cabang";
      this.location.id = "";
      this.location.code = "";
      this.location.name = "";
      this.location.address = "";
    },
    openModalEdit(locationId) {
      this.errors = "";

      const selectedLocation = this.locations.find(
        (location) => location.id === locationId
      );

      this.modal.status = true;
      this.modal.method = "PUT";
      this.modal.methodFuction = "updateLocation";
      this.modal.title = "Edit Produk";
      this.modal.button = "Simpan";
      this.location.id = selectedLocation.id;
      this.location.code = selectedLocation.code;
      this.location.name = selectedLocation.name;
      this.location.address = selectedLocation.address;
    },
    appendLocation(dataLocation) {
      this.dataLocations.push(dataLocation);
    },
    tambahLocation: function () {
      axios
        .post(this.urlLocation, this.location, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          this.appendLocation(response.data.data);

          sweatalert.showAlert("success", response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    editLocation: function () {
      axios
        .put(this.urlLocation + "/" + this.location.id, this.location, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.closeModal();

          var _this = this;

          _this.dataLocations.map(function (location, index) {
            if (location.id == response.data.data.id) {
              _this.dataLocations[index] = {
                id: response.data.data.id,
                code: response.data.data.code,
                name: response.data.data.name,
                address: response.data.data.address,
              };
            }

            return location;
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;

          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
        });
    },
    deleteLocation: function (locationId) {
      if (
        confirm(
          "Anda yakin ingin menghapus produk ini? Ini tidak dapat dibatalkan."
        )
      ) {
        axios
          .delete(this.urlLocation + "/" + locationId, {
            headers: { Authorization: this.authToken },
          })
          .then((response) => {
            this.dataLocations = this.dataLocations.filter(
              (location) => location.id !== locationId
            );
            sweatalert.showAlert("success", response.data.message);
          });
      }
    },
    closeModal() {
      this.modal.status = false;
    },
  },
};
</script>
