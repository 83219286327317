import SignUp from '../views/SignUp.vue';
import SignIn from '../views/SignIn.vue';
import { redirectIfAuthenticated, loggerMiddleware } from '../middleware/index';

export default [
    {
        path: "/sign-in",
        name: "Sign In",
        component: SignIn,
        meta: {
            middleware: [redirectIfAuthenticated, loggerMiddleware],
        },
    },
    {
        path: "/sign-up",
        name: "Sign Up",
        component: SignUp,
        meta: {
            middleware: [redirectIfAuthenticated, loggerMiddleware],
        },
    },
]