<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-users
          v-bind:users="data.users"
          v-bind:locations="data.locations"
          v-bind:roles="data.roles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableUsers from "./components/TableUsers.vue";

export default {
  name: "Users",
  components: {
    TableUsers,
  },
  data() {
    return {
      data: {
        users: [],
        locations: [],
        roles: [],
      },
      url: {
        urlGetUsers: `${this.apiUrl}/user`,
        urlGetLocations: `${this.apiUrl}/location`,
        urlGetRoles: `${this.apiUrl}/role`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataUser();
    this.getDataLocation();
    this.getDataRole();
  },
  methods: {
    getDataUser: function () {
      axios
        .get(this.url.urlGetUsers, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.users = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataLocation: function () {
      axios
        .get(this.url.urlGetLocations, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.locations = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataRole: function () {
      axios
        .get(this.url.urlGetRoles, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.roles = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
