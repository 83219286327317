<template>
  <div class="card mb-4">
    <div class="card-header">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Retur Penjualan</h6>
        </div>
        <div class="col-6 text-end">
          <soft-button
            color="success"
            size="sm"
            variant="outline"
            @click="addSaleReturn"
          >
            Tambah Baru</soft-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-0 w-100">
      <div class="table-responsive p-0">
        <good-table-sale-returns v-bind:saleReturns="this.dataSales">
        </good-table-sale-returns>
      </div>
    </div>
  </div>
  <modal
    :show="modal.status"
    v-if="sale"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-lg"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-9 align-items-center">
                <h5 class="mb-0">Detail Retur Penjualan</h5>
              </div>
              <div class="col-3 text-end">
                <span class="cursor-pointer" @click="this.modal.status = false"
                  >x</span
                >
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <div class="row">
              <!-- <div class="col-12" style="font-family: 'Dot Matrix'"> -->
              <div class="col-12">
                <div class="form-group text-center">
                  <label class="form-label text-sm mb-0">Nama Kasir</label>
                  <div class="text-sm">{{ sale.cashier }}</div>
                </div>
                <div class="row border-bottom">
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0">No Invoice</label>
                      <div class="text-sm">{{ sale.invoice }}</div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0"
                        >Tanggal Invoice</label
                      >
                      <div class="text-sm">{{ sale.date_f }}</div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group text-center">
                      <label class="form-label text-sm mb-0">Pelanggan</label>
                      <div class="text-sm">{{ sale.customer }}</div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center mt-3 mb-2">
                  <label class="form-label text-sm mb-0">Daftar Produk</label>
                </div>
                <div class="table-responsive p-0">
                  <table
                    class="table align-items-center justify-content-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Kode
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Nama Produk
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Harga
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Jumlah
                        </th>
                        <th
                          class="text-uppercase text-secondary text-sm font-weight-bolder opacity-7"
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in sale.details" :key="product.id">
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.item.code }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.item.name }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.price_f }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.quantity }}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex px-2">
                            <div class="my-auto">
                              <h6 class="mb-0 text-sm">
                                {{ product.total_f }}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row my-3">
                  <div class="col-8 text-end">
                    <label class="text-lg">Total</label>
                  </div>
                  <div class="col-4 text-start">
                    <label class="text-lg">{{ sale.total_f }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer border-top">
            <div class="d-flex justify-content-between">
              <!-- <soft-button
                color="info"
                size="sm"
                variant="outline"
                @click="printInvoice()"
                >Cetak</soft-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import Modal from "@/views/components/Modal.vue";
import GoodTableSaleReturns from "./GoodTableSaleReturns.vue";

export default {
  props: ["saleReturns"],
  name: "table-sale-returns",
  components: {
    SoftButton,
    Modal,
    GoodTableSaleReturns,
  },
  data() {
    return {
      dataSales: this.saleReturns,
      sale: null,
      url: {
        urlGetSale: `${this.apiUrl}/sale/`,
        urlDelete: `${this.apiUrl}/sale/`,
      },
      modal: {
        status: false,
        method: "POST",
        title: "",
        button: "Tambah Baru",
        methodFuction: "tambahProduct",
      },
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      errors: null,
    };
  },
  updated() {
    if (this.dataSales.length == 0) {
      this.dataSales = this.saleReturns;
    }
  },
  methods: {
    addSaleReturn() {
      this.$router.push({ name: "Tambah Retur Penjualan" });
    },
    getSale: function (id) {
      this.sale = this.dataSales.find((sale) => {
        return sale.id == id;
      });
      this.modal.status = true;
    },
    printInvoice: function () {
      const prettyPrintIframe = document.createElement("iframe");

      prettyPrintIframe.setAttribute("id", "PrettyPrintFrame");
      prettyPrintIframe.setAttribute("name", "PrettyPrintFrame");
      prettyPrintIframe.setAttribute("style", "display: none;");

      document.body.appendChild(prettyPrintIframe);
      prettyPrintIframe.contentWindow.document.head.innerHTML = this.generateHeaderHtml();
      prettyPrintIframe.contentWindow.document.body.innerHTML = this.generatePrintLayout();

      try {
        const contentWindow = prettyPrintIframe.contentWindow;
        const result = contentWindow.document.execCommand("print", false, null);
        const printListener = contentWindow.matchMedia("print");
        printListener.addListener(function (pl) {
          if (!pl.matches) {
            // remove the hidden iframe from the DOM
            // prettyPrintIframe.remove();
          }
        });

        // if execCommand is unsupported
        if (!result) {
          contentWindow.print();
        }
      } catch (e) {
        // print fallback
        window.frames["PrettyPrintFrame"].focus();
        window.frames["PrettyPrintFrame"].print();
      }
    },
    generatePrintLayout: function () {
      let html = "";

      html += `<div class="text-center">${this.sale.location.toUpperCase()}</div>`;
      html += `<div class="text-center" style="margin: -15px 0 -20px 0">${this.sale.address.toUpperCase()}</div>`;
      html +=
        '<div class="text-center">=====================================================================================================================================================================================================================================</div>';
      html += `<div class="d-flex"> <div style="width: 17%">Tanggal</div> <div style="width: 3%">:</div> <div>${this.sale.date_f}</div> </div>`;
      html += `<div class="d-flex"> <div style="width: 17%">Nomor</div> <div style="width: 3%">:</div> <div>${this.sale.invoice}</div> </div>`;
      html += `<div class="d-flex"> <div style="width: 17%">Pelanggan</div> <div style="width: 3%">:</div> <div>${
        this.sale.customer ?? "-"
      }</div>`;
      html += `</div>`;
      html +=
        '<div class="text-center">----------------------------------------------------------------------------------</div>';
      html += `<div style="width: 95%;">`;
      this.sale.details.forEach((product) => {
        html += `<div> <div style="margin-bottom: -15px;">${product.item.name.toUpperCase()}</div> <div class="d-flex"> <div style="width: 30%;">${
          product.item.code
        }</div> <div style="width: 35%;">${parseInt(
          product.quantity
        )} x ${this.formatNumber(
          parseInt(product.price)
        )}</div> <div class="text-end" style="width: 35%;">${this.formatNumber(
          parseInt(product.total)
        )}</div> </div> </div>`;
      });
      html += `</div>`;
      html +=
        '<div class="d-flex"><div class="text-center" style="width: 93%; overflow: hidden;">----------------------------------------------------------------------------</div><div class="text-end" style="width: 7%;">(+)</div></div>';
      html += `<div style="width: 95%;"> <div class="d-flex"> <div style="width: 30%"></div> <div style="width: 35%">TOTAL</div> <div class="text-end" style="width: 35%">${this.formatNumber(
        parseInt(this.sale.total)
      )}</div> </div> <div class="d-flex"> <div style="width: 30%"></div> <div style="width: 35%">BAYAR</div> <div class="text-end" style="width: 35%">${this.formatNumber(
        parseInt(this.sale.total)
      )}</div> </div> </div> `;
      html +=
        '<div class="d-flex"><div class="text-center" style="w  idth: 93%; overflow: hidden;">----------------------------------------------------------------------------</div><div class="text-end" style="width: 7%;">(-)</div></div>';
      html +=
        '<div style="width: 95%;"> <div class="d-flex"> <div style="width: 30%"></div> <div style="width: 35%">KEMBALIAN</div> <div class="text-end" style="width: 35%">0</div> </div> <div class="d-flex"> <div style="width: 30%"></div> <div style="width: 35%">TOTAL QTY</div> <div class="text-end" style="width: 35%">11</div> </div> </div>';

      html += this.generateFooterHtml();

      return html;
    },
    generateHeaderHtml: function () {
      let headerHtml = "";

      // loop through the styleSheets object and pull in all styles
      for (let i = 0; i < document.styleSheets.length; i++) {
        headerHtml += "<style>";

        try {
          for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
            headerHtml += document.styleSheets[i].cssRules[j].cssText || "";
          }
        } catch (e) {
          console.log(e);
        }

        headerHtml += "</style>";
      }

      headerHtml += this.generateGlobalCss();

      return headerHtml;
    },
    generateGlobalCss: function () {
      let css = "<style>";

      css += "body { padding: 40px 0; font-size:40px}";
      // "body { padding: 40px 0; font-family: 'Dot Matrix'; font-size:40px}";
      css += "table tr { page-break-inside: avoid; }";
      css += "table td { vertical-align: top; padding: 4px 8px;}";
      css += "img { height: 100px !important; width: 100px !important; }";
      css += "@page { margin: 0 0.8cm 0 0.8cm; }";

      css += "</style>";

      return css;
    },
    generateFooterHtml: function () {
      let footerHtml = "";

      footerHtml += `<div class="text-center"> ================================================================================================ </div> <div style="margin-top: -20px">${
        this.sale.date_f_l
      } - ${this.sale.cashier.toUpperCase()}</div> <div class="mt-3 text-center"> <div> Barang Yang Sudah Dibeli</div> <div style="margin-top: -10px">Tidak Bisa DITUKAR atau DIKEMBALIKAN </div> <div class="mt-3">.:Terima Kasih:.</div> </div>`;
      footerHtml += "</body></html>";

      return footerHtml;
    },
    formatNumber: function (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
