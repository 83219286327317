export default ({ next, router }) => {
  if (JSON.parse(localStorage.getItem("user")).role > 2) {
    router.push({
      name: `Daftar Produk`,
    });

    return next();
  }

  return next();
};
