<template>
  <div>
    <vue-good-table
      v-on:row-click="selectProduct"
      :columns="columns"
      :rows="products"
      styleClass="vgt-table"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["products"],
  name: "good-table-products",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: "Kode Produk",
          field: "code",
        },
        {
          label: "Nama Produk",
          field: "name",
        },
        {
          label: "Bardoce Produk",
          field: "barcode",
        },
      ],
      errors: null,
    };
  },
  methods: {
    selectProduct(params) {
      this.$parent.$parent.searchValue =
        params.row.code + " | " + params.row.barcode + " | " + params.row.name;
      this.$parent.$parent.selectProduct();

      this.$parent.$parent.modalProduct.status = false;
    },
    onPageChange(params) {
      console.log(params);
    },
  },
};
</script>
