<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <table-products
          v-if="data.products.length > 0"
          v-bind:products="data.products"
          v-bind:productCategories="data.productCategories"
          v-bind:productSubCategories="data.productSubCategories"
          v-bind:productCommissions="data.productCommissions"
          v-bind:productBrands="data.productBrands"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import TableProducts from "./components/TableProducts";

export default {
  name: "Products",
  components: {
    TableProducts,
  },
  data() {
    return {
      data: {
        products: [],
        productCategories: [],
        productSubCategories: [],
        productCommissions: [],
        productBrands: [],
      },
      url: {
        urlGetProducts: `${this.apiUrl}/product`,
        urlGetProductCategories: `${this.apiUrl}/product/category`,
        urlGetProductSubCategories: `${this.apiUrl}/product/sub-category`,
        urlGetProductCommissions: `${this.apiUrl}/product/commission`,
        urlGetProductBrands: `${this.apiUrl}/product/brand`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getDataProduct();
    this.getDataProductCategories();
    this.getDataProductSubCategories();
    this.getDataProductCommissions();
    this.getDataProductBrands();
  },
  methods: {
    getDataProduct: function () {
      axios
        .get(this.url.urlGetProducts, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.products = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProductCategories: function () {
      axios
        .get(this.url.urlGetProductCategories, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productCategories = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProductSubCategories: function () {
      axios
        .get(this.url.urlGetProductSubCategories, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productSubCategories = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProductCommissions: function () {
      axios
        .get(this.url.urlGetProductCommissions, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productCommissions = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
    getDataProductBrands: function () {
      axios
        .get(this.url.urlGetProductBrands, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          this.data.productBrands = res.data.data;
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
        });
    },
  },
};
</script>
