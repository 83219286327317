<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="transactions"
      styleClass="vgt-table"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
      }"
    >
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";

export default {
  props: ["transactions"],
  name: "good-table-product-detail",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "date",
          type: "date",
          dateInputFormat: "dd-MM-yyyy",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Keterangan",
          field: "keterangan",
        },
        {
          label: "Stok Awal",
          field: "stock_start",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Masuk",
          field: "stock_in",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Keluar",
          field: "stock_out",
          tdClass: "text-end",
          thClass: "text-end",
        },
        {
          label: "Stok Akhir",
          field: "stock_end",
          tdClass: "text-end",
          thClass: "text-end",
        },
      ],
      errors: null,
    };
  },
};
</script>
