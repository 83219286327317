<template>
  <div class="py-4 container-fluid mt-4">
    <div class="mt-4">
      <div class="tab-content">
        <div class="card mb-4">
          <div class="card-body pb-2">
            <form
              ref="form"
              action="#"
              method="post"
              role="form"
              v-on:submit.prevent="submit()"
            >
              <div class="row border-bottom">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="form-label">Kasir</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Admin"
                      v-model="sale.cashier"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="form-label">Tanggal Transaksi</label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Nama Produk"
                      v-model="sale.date"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="form-label">Pelanggan</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Pelanggan"
                      v-model="sale.customer"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <input type="hidden" name="ecsys" :value="sale.ecsys" />
                  <div class="form-group">
                    <label class="form-label">Metode Pembayaran</label>
                    <select class="form-select" v-model="sale.payment_type">
                      <option value="Cash">Cash</option>
                      <option value="Card">Card</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Jenis Pembayaran</label>
                    <select class="form-select" v-model="sale.payment_by">
                      <option value="Cash">Cash</option>
                      <option value="Mandiri Debit">Mandiri Debit</option>
                      <option value="BNI Visa">BNI Visa</option>
                      <option value="XXX">XXX</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Nomor Boarding Pass</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nomor Boarding Pass"
                      v-model="sale.buyer_barcode"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Nomor Penerbangan</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nomor Penerbangan"
                      v-model="sale.buyer_flight_no"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Kode Bandara Tujuan</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Kode Bandara Tujuan"
                      v-model="sale.buyer_destination"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Kewarganegaraan</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Kewarganegaraan"
                      v-model="sale.buyer_nationality"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-3" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Jarak Tempuh</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Jarak Tempuh"
                      v-model="sale.distance"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-3" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Waktu Tempuh</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Waktu Tempuh"
                      v-model="sale.journey_time"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="sale.type == 'airport'">
                  <div class="form-group">
                    <label class="form-label">Catatan</label>
                    <textarea
                      class="form-control"
                      placeholder="Catatan"
                      v-model="sale.remark"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 mt-3">
                  <label class="form-label mb-0">Tambah Produk</label>
                  <div class="row">
                    <div class="col-12 col-xs-3">
                      <div class="form-group">
                        <label class="form-label"
                          >Kode/Nama/Barcode Produk</label
                        >
                        <!-- <Select2 v-model="myValue" :options="myOptions" /> -->
                        <input
                          type="text"
                          class="form-control"
                          list="products"
                          id="exampleDataList"
                          placeholder="Type to search..."
                          autocomplete="off"
                          @input="
                            searchProduct();
                            selectProduct();
                          "
                          v-model="searchValue"
                        />
                        <datalist id="products">
                          <option
                            :value="
                              product.code +
                              ' | ' +
                              product.barcode +
                              ' | ' +
                              product.name
                            "
                            v-for="product in productOptions"
                            :key="product.id"
                          ></option>
                        </datalist>
                        <!-- <input
                          type="text"
                          class="form-control"
                          placeholder="Kode Produk"
                          v-model="searchValue"
                          @change="searchProduct()"
                        /> -->
                      </div>
                    </div>
                    <div class="col-12 col-xs-3">
                      <div class="form-group mb-1">
                        <label class="form-label">Kode Produk</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Kode Produk"
                          readonly
                          v-model="product.code"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-xs-3">
                      <div class="form-group mb-1">
                        <label class="form-label">Nama Produk</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nama Produk"
                          readonly
                          v-model="product.name"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-xs-3">
                      <div class="form-group mb-1">
                        <label class="form-label">Harga</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Harga"
                          :value="formatNumber(product.price)"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-12 col-xs-3">
                      <div class="form-group">
                        <label class="form-label">Jumlah</label>
                        <input
                          type="number"
                          min="0"
                          class="form-control"
                          placeholder="Jumlah"
                          v-model="product.qty"
                          @input="calculateSubTotal()"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-xs-3">
                      <div class="form-group">
                        <label class="form-label">Total</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Total"
                          readonly
                          :value="formatNumber(product.total)"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <soft-button
                        color="info"
                        fullWidth
                        size="sm"
                        variant="outline"
                        type="button"
                        @click="addProduct()"
                      >
                        Tambahkan</soft-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-9 mt-3">
                  <label class="form-label mb-0">Daftar Produk</label>
                  <div class="table-responsive p-0">
                    <table
                      class="table align-items-center justify-content-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Kode Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Nama Produk
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Harga
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Jumlah
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Total
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                          >
                            Opsi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="product in sale.product_list"
                          :key="product.id"
                        >
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">{{ product.code }}</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">{{ product.name }}</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(product.price) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <input
                                  type="number"
                                  style="max-width: 100px"
                                  min="0"
                                  class="form-control"
                                  placeholder="Jumlah"
                                  @input="calculateTotal()"
                                  v-model="product.qty"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-xs">
                                  {{ formatNumber(product.total) }}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex px-2">
                              <div class="my-auto">
                                <h6 class="mb-0 text-sm">
                                  <a
                                    href="javascript:;"
                                    class="text-danger font-weight-bold text-xs"
                                    data-toggle="tooltip"
                                    data-original-title="Hapus Produk"
                                    @click="deleteProduct(product.id)"
                                    >Hapus</a
                                  >
                                </h6>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr style="background-color: #c0f3ff">
                          <td
                            colspan="3"
                            class="text-uppercase font-weight-bolder text-end"
                          >
                            Total
                          </td>
                          <td
                            class="text-uppercase font-weight-bolder text-center"
                          >
                            {{ sale.qty }}
                          </td>
                          <td
                            class="text-uppercase font-weight-bolder text-center"
                          >
                            {{ formatNumber(sale.total) }}
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row mt-4 py-3 border-top">
                <div class="col-12 d-flex justify-content-between">
                  <soft-button
                    type="button"
                    color="warning"
                    size="sm"
                    variant="outline"
                    @click="back()"
                  >
                    Batal
                  </soft-button>
                  <soft-button
                    color="success"
                    size="sm"
                    variant="outline"
                    :disable="disable"
                  >
                    <span
                      v-if="disable"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ disable ? " Loading..." : "Perbarui" }}
                  </soft-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import { sweatalert } from "@/reactivities/sweatalert.js";
import SoftButton from "@/components/SoftButton.vue";
// import Select2 from "vue3-select2-component";

export default {
  name: "edit-sales",
  components: {
    SoftButton,
    // Select2,
  },
  data() {
    return {
      product: {
        id: null,
        code: null,
        name: null,
        qty: null,
        price: null,
        total: null,
        stock: null,
      },
      products: [],
      productOptions: [],
      searchValue: "",
      sale: {
        invoice: "",
        cashier: "",
        date: "",
        customer: "",
        product_list: [],
        location: "",
        total: 0,
        qty: 0,
      },
      locations: [],
      disable: false,
      myValue: "",
      myOptions: ["op1", "op2", "op3"],
      url: {
        urlSubmit: `${this.apiUrl}/sale/${this.$route.params.id}`,
        urlGetSale: `${this.apiUrl}/sale/${this.$route.params.id}`,
        urlGetProducts: `${this.apiUrl}/product/stock`,
      },
      order: {
        column: 2,
        dir: "DESC",
      },
      lengthMenu: [10, 20, 40, 80, 160],
      pageLength: 10,
      authToken:
        "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
    };
  },
  mounted() {
    setTooltip();
    this.getData();
  },
  methods: {
    getSale: function () {
      axios
        .get(this.url.urlGetSale, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          let sale = res.data.data;
          this.sale.invoice = sale.invoice;
          this.sale.cashier = sale.cashier;
          this.sale.date = sale.date;
          this.sale.customer = sale.customer;
          this.sale.location = sale.location_id;

          sale.details.forEach((product) => {
            let selectedProduct = this.products.find((item) => {
              return item.id == product.item_id;
            });
            console.log(product.item_id, selectedProduct);
            selectedProduct.stock =
              parseFloat(selectedProduct.stock) + parseFloat(product.quantity);

            this.sale.product_list.push({
              id: selectedProduct.id,
              code: selectedProduct.code,
              name: selectedProduct.name,
              price: product.price,
              qty: product.quantity,
              discount: product.item_disc,
              total:
                (product.price - product.price * product.item_disc) *
                product.quantity,
              stock: selectedProduct.stock,
            });
          });

          this.products = this.products.filter((item) => {
            return item.stock > 0;
          });

          this.calculateTotal();
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
          this.$router.push({ name: "Penjualan" });
        });
    },
    back: function () {
      this.$router.push({ name: "Penjualan" });
    },
    submit: function () {
      this.disable = true;
      axios
        .put(this.url.urlSubmit, this.sale, {
          headers: { Authorization: this.authToken },
        })
        .then((response) => {
          sweatalert.showAlert("success", response.data.message);
          this.$router.push({ name: "Penjualan" });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          Object.keys(this.errors).forEach((key) => {
            sweatalert.showAlert("error", this.errors[key]);
          });
          this.disable = false;
        });
    },
    getData: function () {
      axios
        .get(this.url.urlGetSale, {
          headers: { Authorization: this.authToken },
        })
        .then((res) => {
          let sale = res.data.data;

          axios
            .get(this.url.urlGetProducts + `?location=${sale.location_id}`, {
              headers: { Authorization: this.authToken },
            })
            .then((res) => {
              this.products = res.data.data;

              this.sale.invoice = sale.invoice;
              this.sale.cashier = sale.cashier;
              this.sale.date = sale.date;
              this.sale.customer = sale.customer;
              this.sale.location = sale.location_id;
              this.sale.payment_type = sale.payment_type;
              this.sale.payment_by = sale.payment_by;
              this.sale.buyer_barcode = sale.buyer_barcode;
              this.sale.buyer_flight_no = sale.buyer_flight_no;
              this.sale.buyer_destination = sale.buyer_destination;
              this.sale.buyer_nationality = sale.buyer_nationality;
              this.sale.distance = sale.distance;
              this.sale.journey_time = sale.journey_time;
              this.sale.remark = sale.remark;
              this.sale.type = sale.location_type;

              sale.details.forEach((product) => {
                let selectedProduct = this.products.find((item) => {
                  return item.id == product.item_id;
                });

                console.log(selectedProduct);

                selectedProduct.stock =
                  parseFloat(selectedProduct.stock) +
                  parseFloat(product.quantity);

                this.sale.product_list.push({
                  id: selectedProduct.id,
                  code: selectedProduct.code,
                  name: selectedProduct.name,
                  price: product.price,
                  qty: product.quantity,
                  discount: product.item_disc,
                  total:
                    (product.price - product.price * product.item_disc) *
                    product.quantity,
                  stock: selectedProduct.stock,
                });
              });

              this.products = this.products.filter((item) => {
                return item.stock > 0;
              });

              this.calculateTotal();
            })
            .catch((error) => {
              sweatalert.showAlert("error", error);
            });
        })
        .catch((error) => {
          sweatalert.showAlert("error", error);
          this.$router.push({ name: "Penjualan" });
        });
    },
    searchProduct: function () {
      this.productOptions = [];
      const reg = new RegExp(this.searchValue);
      this.products.forEach((element) => {
        if (
          element.code.toLowerCase().match(reg) ||
          element.barcode.toLowerCase().match(reg) ||
          element.name.toLowerCase().match(reg)
        ) {
          this.productOptions.push(element);
        }
      });
    },
    selectProduct: function () {
      let product = this.products.find((item) => {
        return (
          item.code + " | " + item.barcode + " | " + item.name ===
          this.searchValue
        );
      });
      if (product) {
        this.product.id = product.id;
        this.product.code = product.code;
        this.product.name = product.name;
        this.product.price = product.price;
        this.product.qty = 1;
        this.product.total = product.price;
        this.product.stock = product.stock;
        this.searchValue = "";
      }
    },
    addProduct: function () {
      if (this.product.id) {
        let product = this.sale.product_list.find((item) => {
          return item.id === this.product.id;
        });
        if (product)
          product.qty = parseFloat(product.qty) + parseFloat(this.product.qty);
        else
          this.sale.product_list.push({
            id: this.product.id,
            code: this.product.code,
            name: this.product.name,
            price: this.product.price,
            qty: this.product.qty,
            discount: 0,
            total: this.product.total,
            stock: this.product.stock,
          });

        this.product.id = null;
        this.product.code = null;
        this.product.name = null;
        this.product.price = 0;
        this.product.qty = null;
        this.product.total = 0;
        this.product.stock = null;

        this.calculateTotal();
      }
    },
    calculateSubTotal: function () {
      if (this.product.qty > this.product.stock) {
        this.product.qty = 0;
        sweatalert.showAlert("error", "Stok produk tidak mencukupi");
      }

      this.product.total = this.product.qty * this.product.price;
    },
    calculateTotal: function () {
      let total = 0;
      let qty = 0;
      this.sale.product_list.forEach((element) => {
        if (element.qty > element.stock) {
          element.qty = 0;
          sweatalert.showAlert("error", "Stok produk tidak mencukupi");
        }

        element.total = parseFloat(element.qty) * element.price;
        total += element.total;
        qty += parseFloat(element.qty);
      });
      this.sale.total = total;
      this.sale.qty = qty;
    },
    deleteProduct: function (id) {
      this.sale.product_list = this.sale.product_list.filter(
        (product) => product.id !== id
      );
    },
    formatNumber: function (number) {
      if (number)
        return (
          "Rp" +
          parseFloat(number)
            .toFixed(2)
            .toString()
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        );

      return "Rp0,00";
    },
  },
};
</script>
