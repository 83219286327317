import {
    reactive
} from 'vue'

export const storeAuth = reactive({
    isLogin: false,
    authToken: null,
    user: {
        name: null,
    }
})